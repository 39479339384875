import api from '../../configs/api'

interface AuthProps {
  email: string
  password: string
}
interface AuthPatientProps {
  cpf: string
}

export const authPost = async ({ email, password }: AuthProps) => {
  try {
    const response = await api.post('/sus/health-post/signin', {
      email,
      password,
    })
    const { user, token } = response.data

    const datasLogin = {
      user,
      uuid: response.data?.health_post?.id,
      specialty: response.data?.prefecture?.speciality,
      name: response.data?.health_post?.name,
      return: response.data?.health_post?.return,
      nationalRegistration: response.data?.prefecture?.national_registration,
    }
    window.localStorage.setItem('@StarBem-web:user', JSON.stringify(datasLogin))

    return { status: true, token, message: 'Login realizado com sucesso' }
  } catch (err: any) {
    if (err.response.status === 400) {
      return { status: false, message: 'Email ou Senha inválidos!' }
    }
    return { status: false, message: 'Ocorreu um erro' }
  }
}

export const authBtoB = async ({ email, password }: AuthProps) => {
  try {
    const response = await api.post('/account/v1/web/companie/auth', {
      document: email,
      password,
    })
    const { user, token } = response.data
    const datasLogin = {
      user,
      specialty: response.data.specialty,
      name: response.data.user.legal_nature,
      uuid: '',
    }
    window.localStorage.setItem('@StarBem-web:user', JSON.stringify(datasLogin))
    return { status: true, token }
  } catch (err: any) {
    if (err.response.status === 400) {
      return { status: false, message: 'Email ou Senha inválidos!' }
    }
    return { status: false, message: 'Ocorreu um erro' }
  }
}

export const authPatient = async ({ cpf }: AuthPatientProps) => {
  try {
    const response = await api.post('/sus/patient/signin', {
      document: cpf,
    })

    const user = response.data
    const { token } = response.data

    window.localStorage.setItem('@StarBem-web:user', JSON.stringify(user))

    return { status: true, message: 'Bem vindo!', token }
  } catch (err: any) {
    const { data } = err.response

    if (err.response.status === 404) {
      return { status: false, message: 'Usuário não cadastrado' }
    }

    return { status: false, message: data.message }
  }
}
