import React from 'react'
import { useHistory } from 'react-router-dom'

// Contexts

import './styles.scss'

// Images
import logo from '../../assets/images/logo.svg'
import logoutIcon from '../../assets/icons/log-out.svg'
import Menu from '../Menu'
import Button from '../Button'
import MenuPatient from '../MenuPatient'
import { useSignIn } from '../../context/authentication/signin.context'
import MenuMobile from '../MenuMobile'

interface HeaderProps {
  type?: string
}

const Header: React.FunctionComponent<HeaderProps> = ({ type }) => {
  const history = useHistory()
  const { logout } = useSignIn()
  return (
    <header id="header">
      <div>
        <button
          type="button"
          className="header-logo transparent-button"
          onClick={() => {
            if (!type) {
              history.push('/dashboard')
            }
          }}
        >
          <img src={logo} alt="Missão Saude" />
        </button>
        {type ? <MenuPatient /> : <Menu />}
        <MenuMobile type={type} />
        <Button
          className="content-logout"
          type="button"
          color="default"
          iconPosition="right"
          icon={logoutIcon}
          iconStyles={{ marginLeft: '10px', width: '24px' }}
          onClick={() => {
            history.replace('/')
            logout()
          }}
        >
          Sair
        </Button>
      </div>
    </header>
  )
}

export default Header
