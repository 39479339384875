import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#181d5f',
    },
    secondary: {
      main: '#ff3f72',
    },
  },
})

export default theme
