import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
`
export const DetailsDoctor = styled.div`
  height: 260px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .content-photo {
    padding: 10px 0px;
    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
    grid-area: photo;
  }
  @media (max-width: 500px) {
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'a photo'
      'b photo';

    > h2 {
      text-align: center;
    }
  }
`
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const DetailsExams = styled.div`
  height: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  order: 3;
`

export const VideoContainer = styled.div`
  height: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  order: 2;

  @media (max-width: 500px) {
    min-height: 285px;
  }
`

export const OpenVideo = styled.div`
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const VideoImage = styled.div`
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background: #f0f2f8;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
