import React, { useEffect, useState } from 'react'
import { TextField as Input } from '@material-ui/core'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import moment from 'moment'
import {
  Button,
  Card,
  Footer,
  Header,
  Loading,
  ModalShow,
  Text,
  ModalHaveReturn,
} from '../../components'
import Form from './Form'
// Context
import { useScheduling } from '../../context/scheduling/scheduling.context'

// Styles
import * as Global from '../../global/styles.global'
import * as S from './styles'

import { alertInfo } from '../../utils/toast'
import { getUserData } from './services'
import FormB2b from './FormB2b'

const RegisterPatient = () => {
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalReturn, setModalReturn] = useState(false)
  const [newRegister, setNewRegister] = useState<any>(null)
  const [cpf, setCpf] = useState('')
  const [userDatas, setUserDatas] = useState<any>(null)
  const { scheduling, verifyScheduleStorage } = useScheduling()
  const b2b = scheduling.uuidPost === ''

  const handleModal = () => {
    setModalReturn(false)
    setModalOpen(true)
  }

  const getUser = async (document = cpf) => {
    setLoading(true)
    const response = await getUserData(document)

    if (response.status) {
      setLoading(false)
      setUserDatas(response.datas)
      if (
        response?.datas?.user &&
        response?.datas?.user?.haveReturn?.length > 0 &&
        !scheduling.userDocument
      ) {
        setModalReturn(true)
      }
    } else {
      setLoading(false)
      setNewRegister({ cpf, status: true })
      alertInfo('Nenhum usuário encontrado, cadastre um novo!')
    }
  }

  const handleCancelDatas = () => {
    setCpf('')
    setUserDatas(null)
  }

  useEffect(() => {
    verifyScheduleStorage()
    if (scheduling.userDocument) {
      setCpf(scheduling.userDocument)
      getUser(scheduling.userDocument)
    }
  }, [])

  return (
    <Global.Container>
      {modalOpen && <ModalShow setModalOpen={setModalOpen} />}
      {modalReturn && <ModalHaveReturn setModalOpen={setModalReturn} />}
      {loading && <Loading title="Aguarde..." />}
      <Header />

      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Card>
          <S.TopContent>
            <div style={{ marginRight: '100px' }}>
              <Text
                style={{ marginBottom: '20px' }}
                size={20}
                weight="semi-bold"
              >
                Dados do paciente
              </Text>
              <div className="right-content">
                <Input
                  id="outlined-basic"
                  variant="outlined"
                  className="input-default"
                  color="secondary"
                  label="CPF"
                  name="cpf"
                  placeholder="CPF"
                  autoComplete="off"
                  type="text"
                  disabled={!!scheduling?.userDocument}
                  inputProps={{ maxLength: 14 }}
                  value={cpfCnpjMask(cpf)}
                  onChange={(e) => {
                    setCpf(cpfCnpjUnmask(e.target.value))
                  }}
                />
                <S.ButtonsContainer>
                  <Button
                    type="button"
                    color="primary"
                    size="small"
                    disabled={
                      cpfValidator(cpf) === false || !!scheduling?.userDocument
                    }
                    onClick={() => getUser()}
                  >
                    Buscar
                  </Button>
                </S.ButtonsContainer>
              </div>
            </div>
            <S.TopConsult>
              <Text
                style={{ marginBottom: '20px' }}
                size={20}
                weight="semi-bold"
              >
                {`Dados da consulta ${
                  scheduling?.userDocument ? '(Retorno)' : ''
                }`}
              </Text>
              <Text style={{ marginBottom: '5px' }} size={14} weight="regular">
                {`Especialidade: ${scheduling.areaName}`}
              </Text>
              <Text style={{ marginBottom: '5px' }} size={14} weight="regular">
                {`Médico: Dr(a). ${scheduling.doctor}`}
              </Text>
              <Text style={{ marginBottom: '5px' }} size={14} weight="regular">
                {`Dia: ${moment(scheduling.dateSchedule).format('DD/MM')}`}
              </Text>
              <Text style={{ marginBottom: '5px' }} size={14} weight="regular">
                {`Horário: ${scheduling.time}`}
              </Text>
            </S.TopConsult>
          </S.TopContent>
        </Card>
        <Card>
          <S.Content>
            {!b2b ? (
              <Form
                handleModal={handleModal}
                datas={userDatas}
                handleCancelDatas={handleCancelDatas}
                newRegister={newRegister}
                setLoading={setLoading}
              />
            ) : (
              <FormB2b
                handleModal={handleModal}
                datas={userDatas}
                handleCancelDatas={handleCancelDatas}
                newRegister={newRegister}
                setLoading={setLoading}
              />
            )}
          </S.Content>
        </Card>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}

export default RegisterPatient
