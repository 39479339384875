import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Text from '../Text'
import './styles.scss'

const MenuPatient = () => {
  const { pathname } = useLocation()
  const [namePatient, setNamePatient] = useState('')

  useEffect(() => {
    const itemStorage = window.localStorage.getItem('@StarBem-web:user')

    if (itemStorage) {
      const data = JSON.parse(itemStorage)

      setNamePatient(data.name)
    }
  }, [])

  return (
    <nav id="menu">
      <ul>
        <li>
          <Link
            className={pathname === '/atendimentos' ? 'active' : ''}
            to="/atendimentos"
          >
            Meus atendimentos
          </Link>
        </li>
      </ul>
      <Text weight="semi-bold" size={16}>
        {namePatient || ' Bem Vindo'}
      </Text>
    </nav>
  )
}

export default MenuPatient
