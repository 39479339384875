import React from 'react'
import './styles.scss'

interface ArrayList {
  uuid: string
  name: string
}

interface SelectProps {
  name: string
  list?: ArrayList[]
  disabled?: boolean
  styles?: any
  defaultValue?: string | number
  placeholder?: string
  onChange?: any
}

const defaultProps: SelectProps = {
  name: '',
  list: [],
  disabled: false,
  styles: {},
  defaultValue: '',
  placeholder: '',
  onChange: () => {},
}

const Select: React.FunctionComponent<SelectProps> = ({
  name,
  list,
  disabled,
  styles,
  defaultValue,
  onChange,
  placeholder,
}) => {
  return (
    <div id="select-container" style={styles}>
      <select
        name={name}
        className="select"
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <option selected disabled>
          {placeholder}
        </option>
        {list?.map((item) => (
          <option value={item.name} key={item.uuid}>
            {item.name ? item.name : item}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.defaultProps = defaultProps

export default Select
