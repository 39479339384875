import moment from 'moment'
import api from '../../../configs/api'

export interface PatientProps {
  uuid: string
  name: string
  cellphone: string
  document: string
  schedulingDate: string
  schedulingTime: string
  areaDescription: string
  city: string
}

export const getScheduledPatients = async (
  uuidPost: string,
  page: number,
  name: string,
  cpf: string,
  city: string
) => {
  const url = `/schedulings/v1/clinics/${uuidPost}/queue?name=${name}&document=${cpf}&city=${city}&page=${page}&limit=10`

  try {
    const response = await api.get(url)

    const datas: PatientProps[] = response.data?.items.map((item: any) => ({
      uuid: item.id,
      name: item.user_name,
      document: item.user_document,
      cellphone: item.cellphone,
      schedulingDate: moment(item.date).format('DD/MM/YYYY'),
      schedulingTime: moment(item.date).utc().format('HH:mm'),
      areaDescription: item.specialty_name,
      is_return: item.return_id,
      city: item.city,
      url: item.url,
    }))

    return {
      status: true,
      datas,
      Maxpages: response.data.nextPage,
    }
  } catch (err) {
    return {
      status: true,
      message: 'Não foi possível encontrar os pacientes',
    }
  }
}

export const cancelSchedule = async (uuid: string) => {
  try {
    await api.post(`/schedulings/v1/clinics/appointments/${uuid}`, {
      cancel_description: 'Cancelado pela Clínica',
    })
    return {
      status: true,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}
