import React from 'react'
import './styles.scss'

interface ButtonProps {
  children: any
  color:
    | 'primary'
    | 'primary-light'
    | 'success'
    | 'warning'
    | 'danger'
    | 'link-danger'
    | 'default'
    | 'blue-dark'
    | 'blue-light'
    | 'outline-primary'
  type: 'button' | 'reset' | 'submit'
  size?: 'small' | 'normal' | 'large'
  styles?: any
  disabled?: boolean
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  icon?: string
  iconPosition?: 'left' | 'right'
  iconStyles?: any
  className?: string
  title?: string
}

const defaultProps: ButtonProps = {
  children: <></>,
  color: 'primary',
  type: 'button',
  size: 'normal',
  styles: {},
  disabled: false,
  onClick: () => {},
  icon: '',
  iconPosition: 'left',
  iconStyles: {},
  title: '',
}

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  color,
  type,
  size,
  styles,
  onClick,
  disabled,
  icon,
  iconPosition,
  className,
  iconStyles,
  title,
}) => {
  return (
    <button
      className={`root ${color} ${size} ${className}`}
      style={styles}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
      title={title}
    >
      {icon && iconPosition === 'left' && (
        <img src={icon} alt="Icon Button" style={iconStyles} />
      )}
      {children}
      {icon && iconPosition === 'right' && (
        <img src={icon} alt="Icon Button" style={iconStyles} />
      )}
    </button>
  )
}

Button.defaultProps = defaultProps

export default Button
