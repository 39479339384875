import React from 'react'
import './styles.scss'

// Images
// import searchIcon from '../../assets/icons/search.svg'

interface SearchProps {
  placeholder?: string | undefined
  value: string | number | undefined
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  name: string
  styles?: any
  onClick?: () => void
  maxLength?: number
}

const Search: React.FunctionComponent<SearchProps> = ({
  placeholder,
  value,
  onChange,
  name,
  styles,
  maxLength,
}) => {
  return (
    <div className="search-container" style={styles}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        maxLength={maxLength}
      />
      {/* <button type="button" onClick={onClick}>
        <img src={searchIcon} alt="Pesquisar" />
      </button> */}
    </div>
  )
}

export default Search
