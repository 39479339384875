import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
interface TextProps {
  color?: string
  weight?: 'regular' | 'semi-bold'
  size?: number
}

// eslint-disable-next-line no-undef
export const TextComponent = styled.h2<TextProps>`
  font-family: Muli, sans-serif;
  color: ${({ color, theme }) => color || theme.black};
  font-weight: ${({ weight }) => (weight === 'regular' ? 300 : 600)};
  font-size: ${({ size }) => size}px;

  @media screen and (max-width: 1000px) {
    font-size: ${({ size }) => Number(size) * 0.08}rem;
  }
  @media screen and (max-width: 750px) {
    font-size: ${({ size }) => Number(size) * 0.07}rem;
  }
`
