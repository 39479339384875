import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
`

export const Header = styled.div`
  width: 100%;
  padding: 15px 70px;
  background-color: #181d5f;

  div {
    margin: 0 auto;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;

      text-align: center;

      color: #ffffff;
    }
  }

  @media screen and (max-width: 540px) {
    padding: 10px;

    div {
      h4 {
        font-size: 12px;
      }
    }
  }
`
export const SectionLogo = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom-width: 5px;
  border-bottom-color: #181d5f;
  border-bottom-style: solid;

  @media screen and (max-width: 540px) {
    height: 100px;
  }
`

export const Logo = styled.div`
  img {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 540px) {
      max-width: 200px;
    }
  }
`
export const ContainerCard = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;

  display: flex;
  justify-content: flex-end;

  img {
    width: 100%;
    position: absolute;
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
    img {
      position: relative;
    }
  }
`

export const Card = styled.div`
  position: relative;
  width: 95%;
  max-width: 450px;
  max-height: 350px;
  background-color: white;
  border-radius: var(--border-radius-default);
  padding: 30px 25px;
  top: 60px;
  right: 120px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media screen and (min-width: 961px) {
    padding: 40px 60px;
  }
  @media screen and (max-width: 540px) {
    top: -30px;
    right: 0px;
    margin: 0px auto;
  }
`

export const AreaButton = styled.div`
  margin-top: 30px;
  z-index: 1000;
  position: fixed;
  bottom: 50px;
  left: 50px;

  @media screen and (max-width: 540px) {
    position: relative;
    margin: 30px auto;
    bottom: 0px;
    left: 0px;
  }
`
