import React from 'react'

import { TextComponent } from './styles'

interface TextInterface {
  children: React.ReactNode
  color?: string
  weight?: 'regular' | 'semi-bold'
  size?: number
  style?: {}
}

const Text: React.FunctionComponent<TextInterface> = ({
  children,
  color,
  size,
  style,
  weight,
}) => {
  return (
    <TextComponent
      weight={weight || 'regular'}
      color={color}
      size={size || 14}
      style={style}
    >
      {children}
    </TextComponent>
  )
}

export default Text
