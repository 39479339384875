import React from 'react'
import './styles.scss'

interface InputProps {
  placeholder?: string
  name: string
  value?: string | number | undefined
  type: 'text' | 'email' | 'number' | 'password' | 'date' | undefined
  label?: boolean
  maxLength?: number | undefined
  disabled?: boolean
  required?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const defaultProps: InputProps = {
  placeholder: '',
  name: '',
  value: '',
  type: 'text',
  label: true,
  disabled: false,
  onChange: () => {},
  required: false,
}

const Input: React.FunctionComponent<InputProps> = ({
  placeholder,
  name,
  value,
  maxLength,
  label,
  type,
  disabled,
  onChange,
  required,
}) => {
  return (
    <div
      id="input-container"
      style={{
        backgroundColor: disabled ? '#f4f4f4' : 'transparent',
        cursor: disabled ? 'no-drop' : 'auto',
      }}
    >
      <input
        className={label ? 'input' : 'input-no-padd'}
        placeholder={placeholder}
        name={name}
        value={value}
        maxLength={maxLength}
        type={type}
        disabled={disabled}
        onChange={onChange}
        required={required}
        id={name}
      />
      {label && (
        <label
          htmlFor={name}
          className="label"
          style={{
            cursor: disabled ? 'no-drop' : 'auto',
          }}
        >
          {placeholder}
        </label>
      )}
    </div>
  )
}

Input.defaultProps = defaultProps

export default Input
