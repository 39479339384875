import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Login from '../containers/Authentication/Login'
import LoginHabitual from '../containers/Authentication/LoginHabitual'
import Calls from '../containers/Calls'
import { Historic } from '../containers/Dashboard/Historic'
import Home from '../containers/Dashboard/Home'
import { Patient } from '../containers/Dashboard/Patients'
import { Doctors } from '../containers/Doctors'

import RegisterPatient from '../containers/RegisterPatient'
import { useSignIn } from '../context/authentication/signin.context'
import { SchedulingProvider } from '../context/scheduling/scheduling.context'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { verifySignIn } = useSignIn()

  return (
    <SchedulingProvider>
      <Route
        {...rest}
        render={(props) =>
          verifySignIn() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    </SchedulingProvider>
  )
}
const PrivateRoutePatient = ({ component: Component, ...rest }: any) => {
  const { verifySignInPatient } = useSignIn()

  return (
    <Route
      {...rest}
      render={(props) =>
        verifySignInPatient() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  )
}
interface Props {
  habitual: boolean
}
const Routes = ({ habitual }: Props) => {
  console.log('wind', window.location.host)
  return (
    <Router>
      <Switch>
        {habitual && <Route path="/" exact component={LoginHabitual} />}
        {!habitual && <Route path="/" exact component={Login} />}
        <PrivateRoute path="/dashboard" exact component={Home} />
        <PrivateRoute path="/pacientes" exact component={Patient} />
        <PrivateRoute path="/medicos" exact component={Doctors} />
        <PrivateRoute path="/paciente" exact component={RegisterPatient} />
        <PrivateRoute path="/historico" exact component={Historic} />
        <PrivateRoutePatient path="/atendimentos" exact component={Calls} />
      </Switch>
    </Router>
  )
}

export default Routes
