import React, { useEffect, useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import * as Global from '../../global/styles.global'

import * as S from './styles'
import { Button, Card, Footer, Header, Loading, Text } from '../../components'
import { getDoctors, selectTime } from './services'
import ModalTime from '../../components/ModalTime'
import { useScheduling } from '../../context/scheduling/scheduling.context'
import { alertDanger } from '../../utils/toast'

interface DoctorsProps {
  name: string
  img: string
  uuid: string
  document: string
}

export const Doctors = () => {
  const [allDoctors, setAllDoctors] = useState<DoctorsProps[]>()
  const [modalOpen, setModalOpen] = useState(false)
  const [times, setTimes] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const { scheduling, setScheduling } = useScheduling()

  useEffect(() => {
    const getAllDoctors = async (cnpj: string) => {
      setLoading(true)
      const response = await getDoctors(
        scheduling.dateSchedule,
        scheduling.areaId,
        cnpj
      )
      if (response.status) {
        setAllDoctors(response.doctors)
      } else {
        console.log(response)
      }
      setLoading(false)
    }

    const dataStorage = window.localStorage.getItem('@StarBem-web:user')
    if (dataStorage) {
      const data = JSON.parse(dataStorage)
      getAllDoctors(data.nationalRegistration)
    }
  }, [])

  const handleClick = async (uuidDoctor: string, nameDoctor: string) => {
    setLoading(true)
    const response = await selectTime({
      date: scheduling.dateSchedule,
      uuidDoctor,
      uuidArea: scheduling.areaId,
    })

    if (response.status) {
      if (response.availableTime.length === 0) {
        setLoading(false)
        alertDanger('Nenhum horário disponivel!')
      } else {
        const datas = { ...scheduling, doctor: nameDoctor, uuidDoctor }
        setScheduling(datas)
        window.localStorage.setItem('ScheduleStorage', JSON.stringify(datas))
        setTimes(response.availableTime)
        setModalOpen(true)
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Global.Container>
      {loading && <Loading title="Aguarde, estamos buscando os Médicos..." />}
      <Header />
      {modalOpen && <ModalTime setModalOpen={setModalOpen} times={times} />}
      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Card>
          <div className="patients-top">
            <Text size={20} weight="semi-bold">
              Médicos disponíveis
            </Text>
            {scheduling && (
              <Text size={20} weight="regular" style={{ marginLeft: '10px' }}>
                {` - ${scheduling.areaName}`}
              </Text>
            )}
          </div>
        </Card>
        <Card>
          <S.TablePatients>
            <Table className="table-content">
              <TableHead>
                <TableRow>
                  <TableCell width="40%">Nome</TableCell>
                  <TableCell width="40%">Documento</TableCell>
                  <TableCell width="10%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {allDoctors?.map((doc) => (
                  <TableRow key={doc.uuid}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="primary-col"
                    >
                      {doc.name}
                    </TableCell>
                    <TableCell align="left">{doc.document}</TableCell>
                    <TableCell align="right">
                      <Button
                        type="button"
                        color="primary-light"
                        onClick={() => handleClick(doc.uuid, doc.name)}
                        size="small"
                        styles={{ width: 100 }}
                      >
                        Selecionar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </S.TablePatients>
        </Card>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}
