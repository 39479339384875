import React from 'react'
import './styles.scss'

import { useHistory } from 'react-router-dom'
import { Button } from '../index'
import Text from '../Text'
import { useScheduling } from '../../context/scheduling/scheduling.context'

interface ModalShowProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  times: string[]
}

const ModalTime: React.FunctionComponent<ModalShowProps> = ({
  setModalOpen,
  times,
}) => {
  const history = useHistory()
  const { scheduling, setScheduling } = useScheduling()
  const handleClick = (timeId: string, time: string) => {
    const datas = { ...scheduling, timeId, time }
    setScheduling(datas)
    window.localStorage.setItem('ScheduleStorage', JSON.stringify(datas))
    history.push('/paciente')
  }

  return (
    <>
      <div className="modal-content-time">
        <div className="modal-container-time">
          <Text
            style={{ marginBottom: '25px', alignSelf: 'flex-start' }}
            weight="semi-bold"
            size={24}
          >
            Selecione um horário:
          </Text>
          <div className="buttons-container-time">
            {times.map((time: any) => (
              <Button
                key={time?.slot_id}
                type="button"
                color="primary-light"
                styles={{ margin: 10 }}
                onClick={() => handleClick(time?.slot_id, time?.time)}
              >
                {time.time}
              </Button>
            ))}
          </div>
          <Button
            type="button"
            color="primary"
            // @ts-ignore
            onClick={() => setModalOpen(false)}
            styles={{ marginTop: 30, width: 130, alignSelf: 'flex-end' }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  )
}

export default ModalTime
