/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react'
import Video from 'twilio-video'
import Participant from '../Participant'

import './styles.scss'

// Images
import amp from '../../../assets/images/full.svg'
import endCall from '../../../assets/icons/end-call.svg'

const Room = ({ roomName, token, handleLogout }) => {
  const [participants, setParticipants] = useState([])
  const [room, setRoom] = useState(null)
  const [loading, setLoading] = useState(true)

  const participantConnected = (participant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant])
  }

  const participantDisconnected = (participant) => {
    setRoom((currentRoom) => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        currentRoom.localParticipant.tracks.forEach((trackPublication) => {
          trackPublication.track.stop()
        })

        currentRoom.disconnect()
        return null
      } else {
        return currentRoom
      }
    })
    handleLogout()
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    )
  }

  useEffect(() => {
    Video.connect(token, {
      name: roomName,
    })
      .then((room) => {
        setLoading(false)
        setRoom(room)
        room.participants.forEach(participantConnected)
        room.on('participantConnected', participantConnected)

        room.on('participantDisconnected', participantDisconnected)
        room.once('disconnected', () =>
          room.participants.forEach(participantDisconnected)
        )
      })
      .catch((error) => {
        setLoading(false)
        console.log('ERROR CONNECT CAM: ', error)
      })

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop()
          })

          currentRoom.disconnect()
          return null
        } else {
          return currentRoom
        }
      })
    }
  }, [roomName, setRoom, token, handleLogout])

  const handleLeaveRoom = useCallback(() => {
    handleLogout()
  }, [handleLogout])

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ))

  const handleFull = () => {
    const element = document.getElementById('container_video')
    const participant = document.getElementById('participant_area')

    if (element?.className === 'room') {
      element?.classList.remove('room')
      element?.classList.add('room-full')
      participant?.classList.add('full-area')
    } else {
      element?.classList.remove('room-full')
      element?.classList.add('room')
      participant?.classList.remove('full-area')
    }
  }

  return (
    <div id="container_video" className="room">
      <button
        type="button"
        className="btn-full-screen"
        onClick={() => handleFull()}
      >
        <img src={amp} alt="Deixar em tela cheia" />
      </button>
      <div id="participant_area" className="remote-participants">
        {remoteParticipants?.length === 0 && (
          <div className="participant-waiting">
            {loading ? <p>Carregando ...</p> : <p>Aguarde o médico...</p>}
          </div>
        )}
        {remoteParticipants.length === 1 && remoteParticipants}
      </div>
      <div className={`local-participant`}>
        {room && (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
          />
        )}
      </div>
      <div className="buttons-call">
        <button
          type="button"
          className={`btn-call btn-danger ${loading && 'disabled'}`}
          onClick={() => handleLeaveRoom()}
        >
          <img
            src={endCall}
            alt="Icone de um telefone que representa o botão que encerra a video chamada"
          />
        </button>
      </div>
    </div>
  )
}

export default Room
