import React, { useEffect, useState, useRef } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  createMuiTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import { ThemeProvider } from '@material-ui/styles'

import DateFnsUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { telephoneMask } from 'js-essentials-functions'
import { CSVLink } from 'react-csv'

import {
  Button,
  Card,
  Footer,
  Header,
  Loading,
  Text,
} from '../../../components'
import { alertDanger } from '../../../utils/toast'
import { servicesGetSchedulings, SchedulingDatas } from './services'
import * as Global from '../../../global/styles.global'
import * as S from './styles'
import 'moment/locale/pt-br'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff3f72',
    },
  },
})

const headers = [
  { label: 'Nome do Paciente', key: 'namePatient' },
  { label: 'Data da Consulta', key: 'dateScheduling' },
  { label: 'Hora da Consulta', key: 'timeScheduling' },
  { label: 'Telefone', key: 'tellphone' },
  { label: 'Especialidade', key: 'specialty' },
  { label: 'Município', key: 'city' },
  { label: 'Estado', key: 'state' },
  { label: 'Status', key: 'status' },
  { label: 'Tipo de consulta', key: 'is_return' },
  { label: 'Nome do Médico', key: 'nameDoctor' },
  { label: 'Documento do Médico', key: 'documentDoctor' },
]

const todayDay = new Date()
const firstDay = new Date(todayDay.getFullYear(), todayDay.getMonth(), 1)

export const Historic = () => {
  const [loading, setLoading] = useState(false)
  const [numPage, setNumPage] = useState(1)
  const [maxPages, setMaxPages] = useState(1)
  const [initialDate, setInitialDate] = useState(firstDay)
  const [endDate, setEndDate] = useState(todayDay)
  const [schedulings, setSchedulings] = useState<SchedulingDatas[]>()
  const [uuidPost, setUuidPost] = useState('')
  const [schedulingsExportCsv, setSchedulingsExportCsv] = useState<any[]>([])
  const [status, setStatus] = useState('all')

  const tableRef = useRef(null)
  const csvInstance = useRef<any | null>(null)

  useEffect(() => {
    const getUuidPost = () => {
      const dados = window.localStorage.getItem('@StarBem-web:user')
      if (dados) {
        const { uuid } = JSON.parse(dados)
        setUuidPost(uuid)
      }
    }
    getUuidPost()
  }, [])

  const limit = 10
  const start = `${moment(initialDate).format('YYYY-MM-DD')}`
  const end = `${moment(endDate).format('YYYY-MM-DD')}`

  const getSchedulingsData = async (page = numPage) => {
    setLoading(true)
    const response = await servicesGetSchedulings({
      uuidPost,
      page,
      limit,
      start,
      end,
      status,
    })
    if (response.status) {
      setMaxPages(response.maxPages || 1)
      setSchedulings(response.datas)
    } else {
      setSchedulings([])
      alertDanger(response.message || 'Não foi possível encontrar os pacientes')
    }

    setLoading(false)
  }

  useEffect(() => {
    if (uuidPost !== '') {
      getSchedulingsData()
    }
  }, [numPage, initialDate, endDate, uuidPost, status])

  const getAllschedulingsDate = async (type: string) => {
    setLoading(true)
    const allFinished = await servicesGetSchedulings({
      uuidPost,
      page: 1,
      limit: 100000,
      start,
      end,
      status: 'all',
    })

    const allCanceled = await servicesGetSchedulings({
      uuidPost,
      page: 1,
      limit: 100000,
      start,
      end,
      status: 'canceled',
    })

    if (allFinished.status && allFinished.datas && allCanceled?.datas) {
      if (type === 'csv') {
        setSchedulingsExportCsv([...allFinished.datas, ...allCanceled?.datas])
      }
    } else {
      alertDanger(
        allFinished.message || 'Não foi possível encontrar os pacientes'
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (
      schedulingsExportCsv &&
      schedulingsExportCsv.length > 0 &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setSchedulingsExportCsv([])
      })
    }
  }, [schedulingsExportCsv])

  // const Modal = () => {
  //   return (
  //     <>
  //       (
  //       <S.Modal>
  //         <div className="modal-container">
  //           <S.CloseButton onClick={() => setModal(false)}>X</S.CloseButton>
  //           <Text
  //             style={{ marginBottom: '25px', textAlign: 'start' }}
  //             weight="semi-bold"
  //             size={20}
  //           >
  //             Exportar como:
  //           </Text>
  //           <S.FooterModal>
  //             <Button
  //               type="button"
  //               color="primary-light"
  //               // @ts-ignore
  //               onClick={() => getAllschedulingsDate('pdf')}
  //               styles={{ marginTop: 10, width: 200, marginRight: 5 }}
  //             >
  //               <PictureAsPdf style={{ marginRight: 10 }} fontSize="large" />
  //               PDF
  //             </Button>
  //             <Button
  //               type="button"
  //               color="primary"
  //               // @ts-ignore
  //               onClick={() => {
  //                 getAllschedulingsDate('csv')
  //               }}
  //               styles={{ marginTop: 10, width: 200, marginRight: 5 }}
  //             >
  //               <Description style={{ marginRight: 10 }} fontSize="large" />
  //               CSV
  //             </Button>
  //           </S.FooterModal>
  //         </div>
  //       </S.Modal>
  //     </>
  //   )
  // }

  return (
    <Global.Container>
      {loading && <Loading title="Aguarde..." />}
      <Header />
      {/* {modal && Modal()} */}
      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Card>
          <S.ContainerFilter>
            <Text size={24} weight="semi-bold" style={{ marginRight: '40px' }}>
              Histórico
            </Text>
            <Text size={14} weight="regular" style={{ marginRight: '20px' }}>
              Escolher periodo
            </Text>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider locale="pt" utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Data inicial"
                  format="DD/MM/YYYY"
                  value={initialDate}
                  maxDate={endDate}
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date: any) => {
                    setInitialDate(date)
                    setNumPage(1)
                  }}
                  color="secondary"
                  style={{
                    width: 180,
                    marginRight: '20px',
                  }}
                />
                <KeyboardDatePicker
                  color="secondary"
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Data final"
                  format="DD/MM/YYYY"
                  value={endDate}
                  minDate={initialDate}
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date: any) => {
                    setEndDate(date)
                    setNumPage(1)
                  }}
                  style={{ width: 180, marginRight: '20px' }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div
              style={{
                justifySelf: 'end',
                alignSelf: 'flex-end',
              }}
            >
              <FormControl
                variant="outlined"
                style={{
                  display: 'flex',
                  margin: '5px 0',
                  minWidth: '200px',
                  width: '100%',
                }}
              >
                <InputLabel color="secondary" id="outlined-start-adornment">
                  Status
                </InputLabel>
                <Select
                  label="Especialidades"
                  style={{
                    fontSize: '12px',
                  }}
                  id="outlined-basic"
                  name="specialty"
                  color="secondary"
                  defaultValue="all"
                  value={status}
                  onChange={(e: any) => setStatus(e?.target?.value)}
                >
                  <MenuItem value="all">Finalizados</MenuItem>
                  <MenuItem value="canceled">Cancelados</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button
              type="button"
              color="primary-light"
              onClick={() => {
                getAllschedulingsDate('csv')
              }}
              size="small"
              styles={{ marginLeft: 20 }}
            >
              Exportar relatório
            </Button>

            <CSVLink
              data={schedulingsExportCsv}
              separator=";"
              headers={headers}
              asyncOnClick
              ref={csvInstance}
              filename="Relatório.csv"
            />
          </S.ContainerFilter>
        </Card>
        <Card>
          <S.TablePatients>
            <Table className="table-content" ref={tableRef}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data da consulta</TableCell>
                  <TableCell>Horário</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Especialidade</TableCell>
                  <TableCell>Município</TableCell>
                  <TableCell>Tipo de consulta</TableCell>
                  <TableCell>Status</TableCell>
                  {status === 'all' && (
                    <TableCell width="13%">Prescrição Médica</TableCell>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {schedulings &&
                  schedulings?.map((patient: any, index: number) => (
                    <TableRow key={String(index)}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="primary-col"
                      >
                        {patient.namePatient || ''}
                      </TableCell>
                      <TableCell align="left">
                        {patient.dateScheduling || '-'}
                      </TableCell>
                      <TableCell align="left">
                        {patient.timeScheduling || '-'}
                      </TableCell>
                      <TableCell align="left">
                        {telephoneMask(patient.tellphone || '')}
                      </TableCell>
                      <TableCell align="left">{patient.specialty}</TableCell>
                      <TableCell align="left">{patient.city}</TableCell>
                      <TableCell align="left">{patient.is_return}</TableCell>
                      <TableCell align="left">{patient.status}</TableCell>
                      {status === 'all' && (
                        <TableCell align="right">
                          {patient.exams && (
                            <Button
                              type="button"
                              color="outline-primary"
                              size="small"
                            >
                              <a
                                target="_blank"
                                href={patient.exams}
                                rel="noreferrer"
                                style={{
                                  textDecoration: 'none',
                                  color: '#ff3f72',
                                }}
                              >
                                Ver Prescrição
                              </a>
                            </Button>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {schedulings?.length !== 0 && maxPages !== 1 && (
              <S.Pagination>
                <Pagination
                  count={maxPages}
                  color="secondary"
                  page={numPage}
                  onChange={(e, v) => setNumPage(v)}
                />
              </S.Pagination>
            )}
          </S.TablePatients>
        </Card>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}
