import api from '../../../configs/api'

// eslint-disable-next-line consistent-return
export const getAvailableDays = async (
  areaUuid: string,
  schedulingDate: string
) => {
  try {
    const res = await api.post(`schedulings/v2/verify-dates`, {
      specialty_id: areaUuid,
      scheduling_date: schedulingDate.replace('/', '-'),
    })

    return {
      status: 200,
      dates: res.data.days,
    }
  } catch (err: any) {
    return {
      status: false,
      message: 'Não possível dias disponíveis para a especialidade.',
    }
  }
}

export const getSpecialties = async (uuid: string) => {
  try {
    const res = await api.get(`/sus/health-post/${uuid}`)

    return {
      status: 200,
      specialties: res.data.specialties,
    }
  } catch (err: any) {
    return {
      status: false,
      message:
        err?.response?.data?.message ||
        'Não foi possível obter as especialidades.',
    }
  }
}
