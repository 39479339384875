import api from '../../configs/api'
import { UserDataProps } from './interfaces'

export const getUserData = async (document: string) => {
  try {
    const response = await api.post('/sus/patient/signin', {
      document,
      // health_post_uuid: idPost,
    })

    const datas: UserDataProps = {
      user: {
        uuid: response?.data?.id,
        name: response?.data?.name,
        birthday: response?.data?.personal_data?.birthdate,
        cellphone: response?.data?.personal_data?.cellphone,
        email: response?.data?.email,
        gender: response?.data?.personal_data?.gender,
        weight: String(response?.data?.medical_record?.weight || ''),
        height: String(response?.data?.medical_record?.height || ''),
        document,
        haveReturn: response?.data?.haveReturn,
      },
      address: {
        zip: response?.data?.address?.zipcode,
        street: response?.data?.address?.street,
        number: response?.data?.address?.number,
        city: response?.data?.address?.city,
        state: response?.data?.address?.state,
        neighborhood: response?.data?.address?.neighborhood,
        complement: response?.data?.address?.complement,
      },
    }
    return {
      status: true,
      datas,
    }
  } catch (err: any) {
    console.log(err.response)
    return {
      status: false,
    }
  }
}

interface CreateSchedulingProps {
  userUuid: string
  slotId: string
  heathPostUuid: string
  returnId: string
}

export const createScheduling = async ({
  userUuid,
  slotId,
  heathPostUuid,
  returnId,
}: CreateSchedulingProps) => {
  try {
    await api.post('/schedulings/v1/clinics/appointments', {
      user_id: userUuid,
      post_id: heathPostUuid,
      slot_id: slotId,
      return_id: returnId,
    })
    return {
      status: true,
    }
  } catch (err: any) {
    return {
      status: false,
      message: err?.response?.data?.message,
    }
  }
}

interface UserProps {
  name: string
  email: string
  password: string
  document: string
  birthday: string
  cellphone: string
  type: 7
  street: string
  number: string
  complement: string
  zip: string
  neighborhood: string
  city: string
  state: string
  country: string
  gender: string
  weight: string
  height: string
}
interface SafeMedicalProps {
  patient: UserProps
  uuid?: string
}

export const createPatient = async ({ patient }: SafeMedicalProps) => {
  try {
    const response = await api.post('/sus/patient', {
      name: patient.name,
      email: patient.email,
      document: patient.document,
      birth_day: patient.birthday,
      cellphone: patient.cellphone,
      zip: patient.zip,
      street: patient.street,
      number: patient.number,
      city: patient.city,
      state: patient.state,
      neighborhood: patient.neighborhood,
      complement: patient.complement,
      gender: patient.gender,
      weight: patient.weight,
      height: patient.height,
    })

    return {
      status: true,
      uuidUser: response?.data.item.id,
    }
  } catch (err: any) {
    return {
      status: false,
      message: err.data.data,
    }
  }
}

export const updateDatasPatient = async ({
  patient,
  uuid,
}: SafeMedicalProps) => {
  try {
    await api.put(`/sus/patient/${uuid}`, {
      name: patient.name,
      email: patient.email,
      document: patient.document,
      birth_day: patient.birthday,
      cellphone: patient.cellphone,
      zip: patient.zip,
      street: patient.street,
      number: patient.number,
      city: patient.city,
      state: patient.state,
      neighborhood: patient.neighborhood,
      complement: patient.complement,
      gender: patient.gender,
      weight: patient.weight,
      height: patient.height,
    })
    return {
      status: true,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}
interface UserB2bProps {
  nationalRegistration: string
  user: {
    name: string
    document: string
    birthday: string
    email: string
    cellphone: string
  }
}

export const registerUserB2b = async ({
  nationalRegistration,
  user,
}: UserB2bProps) => {
  try {
    const response = await api.post('/account/v1/b2b/company/user/create', {
      national_registration: nationalRegistration,
      user,
    })
    return {
      status: true,
      uuid: response.data.item.uuid,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}
