import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import ptLocale from '@fullcalendar/core/locales/pt-br'

// Context

// Styles
import { useHistory } from 'react-router-dom'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import moment from 'moment'
import * as Global from '../../../global/styles.global'

import * as S from './styles'

// Components
import { IScheduling } from './Interfaces'
import {
  Header,
  Footer,
  Card,
  Loading,
  Text,
  Button,
  ModalReturn,
} from '../../../components'
import { getAvailableDays, getSpecialties } from './services'
import {
  useScheduling,
  defaultScheduling,
} from '../../../context/scheduling/scheduling.context'
import { alertInfo } from '../../../utils/toast'

interface SpecialtiesProps {
  id: string
  name: string
}
const Home = () => {
  // // State
  const [schedulingList, setSchedulingList] = useState<IScheduling[]>([])
  const [specialties, setSpecialties] = useState<SpecialtiesProps[]>([])
  const [specialty, setSpecialty] = useState('')
  const [loading, setLoading] = useState(false)
  const [isReturn, setIsReturn] = useState(false)
  const [uuid, setUuid] = useState('')
  const [schedulingDate, setSchedulingDate] = useState('')

  const { scheduling, setScheduling } = useScheduling()
  const [modal, setModal] = useState(false)
  const [userReturn, setUserReturn] = useState<any>({})
  const history = useHistory()
  const screen = window.innerWidth

  async function getDays(name = specialty, day: string) {
    setLoading(true)
    const uuidArea = specialties.find((sp: any) => sp.name === name)?.id || ''
    const response = await getAvailableDays(uuidArea, day)

    if (response.status) {
      if (response?.dates.length === 0) {
        setSchedulingList([])
        alertInfo(
          'Não encontramos nenhuma agenda para a especialidade esse mês'
        )
      }
      const dates = response?.dates?.map((dat: any) => ({
        start: dat,
        allDay: true,
        display: 'background',
        title: screen < 500 ? 'Hor. Disp.' : 'Horário disponivel',
        id: dat,
        areaId: uuidArea,
        areaName: name,
      }))
      setSchedulingList(dates)
    } else {
      setSchedulingList([])
      alertInfo(response?.message || 'Erro ao buscar agenda')
    }
    setLoading(false)
  }
  const getSpecialty = async () => {
    const user = window.localStorage.getItem('@StarBem-web:user')
    if (user) {
      const data = JSON.parse(user)
      setLoading(true)
      const allSpecialty = await getSpecialties(data.uuid)
      setSpecialties(allSpecialty.specialties)
      setUuid(data.uuid)
      setIsReturn(!!data.return)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSpecialty()
  }, [])

  const handleSpecialty = (e: any) => {
    setScheduling(defaultScheduling)
    window.localStorage.setItem(
      'ScheduleStorage',
      JSON.stringify(defaultScheduling)
    )
    setSpecialty(e.target.value)
    setUserReturn({})
    getDays(e.target.value, schedulingDate)
  }

  const handleAppointmentSelection = (
    dateSchedule: string,
    areaId: string,
    areaName: string
  ) => {
    const datas = {
      ...scheduling,
      ...userReturn,
      dateSchedule,
      areaId,
      areaName,
      uuidPost: uuid,
    }

    setScheduling(datas)
    window.localStorage.setItem('ScheduleStorage', JSON.stringify(datas))
    history.push('/medicos')
  }
  useEffect(() => {
    if (userReturn?.isReturn) {
      setSpecialty(userReturn.specialty)
      getDays(userReturn.specialty, schedulingDate)
    }
  }, [userReturn])

  return (
    <Global.Container>
      {loading && <Loading title="Aguarde, estamos carregando a agenda..." />}
      <Header />
      {modal && (
        <ModalReturn
          modalOpen={modal}
          setModalOpen={setModal}
          setUserReturn={setUserReturn}
        />
      )}
      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Card
          styles={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <S.TopContent>
            <Text style={{ marginRight: '15px' }} size={20} weight="semi-bold">
              Filtrar por especialidade
            </Text>
            <div>
              <FormControl
                variant="outlined"
                style={{
                  display: 'flex',
                  margin: '5px 0',
                  minWidth: '200px',
                  width: '100%',
                }}
              >
                <InputLabel color="secondary" id="outlined-start-adornment">
                  Especialidades
                </InputLabel>
                <Select
                  label="Especialidades"
                  style={{
                    fontSize: '12px',
                  }}
                  id="outlined-basic"
                  name="specialty"
                  color="secondary"
                  value={specialty}
                  onChange={handleSpecialty}
                >
                  {specialties?.map((g) => (
                    <MenuItem key={g.id} value={g.name}>
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </S.TopContent>
          {isReturn && (
            <Button
              size="large"
              styles={{ width: 300 }}
              type="button"
              color="outline-primary"
              onClick={() => {
                setModal(true)
              }}
            >
              Pacientes com retorno
            </Button>
          )}
        </Card>
        <Card>
          <S.CalendarView>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text
                style={{ marginBottom: '15px' }}
                size={24}
                weight="semi-bold"
              >
                {`Agenda ${
                  userReturn.isReturn ? `- Retorno ${userReturn.name}` : ''
                }`}
              </Text>
              <Text weight="regular" size={16}>
                {`Data: ${moment().format(
                  'DD/MM/YYYY'
                )} - Hora: ${moment().format('HH:mm')} `}
              </Text>
            </div>
            <FullCalendar
              datesSet={(e: any) => {
                setSchedulingDate(moment(e?.startStr).format('YYYY/MM'))
                if (specialty) {
                  getDays(specialty, moment(e?.startStr).format('YYYY/MM'))
                }
              }}
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locale={ptLocale}
              dayHeaderFormat={{
                weekday: screen < 500 ? 'short' : 'long',
              }}
              timeZone="America/Sao_Paulo"
              firstDay={1}
              titleFormat={{ year: 'numeric', month: 'long' }}
              events={schedulingList}
              eventClick={(info: any) =>
                handleAppointmentSelection(
                  info.event.id,
                  info.event.extendedProps?.areaId,
                  info.event.extendedProps?.areaName
                )
              }
              showNonCurrentDates={false}
            />
          </S.CalendarView>
        </Card>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}

export default Home
