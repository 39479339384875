import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const TopContent = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 0px 70px;

  display: flex;
  align-items: center;

  @media screen and (max-width: 961px) {
    padding: 0px 20px;
    flex-wrap: wrap;
  }
  h2 {
    @media screen and (max-width: 450px) {
      margin-bottom: 15px;
    }
  }
`

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
`

export const CalendarView = styled.div`
  width: 100%;
  height: 100%;
`
