import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { cpfCnpjMask, telephoneMask } from 'js-essentials-functions'
import {
  Button,
  Card,
  Footer,
  Header,
  Loading,
  Text,
} from '../../../components'

import * as Global from '../../../global/styles.global'

import * as S from './styles'
import Search from '../../../components/Search'
import { cancelSchedule, getScheduledPatients, PatientProps } from './services'
import { alertDanger, alertSuccess } from '../../../utils/toast'

export const Patient = () => {
  const [patients, setPatients] = useState<PatientProps[]>()
  const [loading, setLoading] = useState(false)
  const [numPage, setNumPage] = useState(1)
  const [maxPage, setMaxPages] = useState(1)
  const [modal, setModal] = useState('')
  const [uuidPost, setUuidPost] = useState('')
  const [cpf, setCpf] = useState('')
  const [name, setName] = useState('')
  const [city, setCity] = useState('')

  useEffect(() => {
    const getUuidPost = () => {
      const dados = window.localStorage.getItem('@StarBem-web:user')
      if (dados) {
        const { uuid } = JSON.parse(dados)
        setUuidPost(uuid)
      }
    }
    getUuidPost()
  }, [])

  const getPatients = useCallback(
    async (
      uuid = uuidPost,
      page = numPage,
      nameUser = name,
      cpfUser = cpf,
      cityName = city
    ) => {
      setLoading(true)
      const response = await getScheduledPatients(
        uuid,
        page,
        nameUser,
        cpfUser,
        cityName
      )
      if (response.status) {
        setMaxPages(response.Maxpages)
        setPatients(response.datas)
        setLoading(false)
      } else {
        alertDanger('Não foi possível encontrar os pacientes')
        setLoading(false)
      }
    },
    [uuidPost, numPage, name, cpf, city]
  )

  useEffect(() => {
    if (uuidPost) {
      getPatients()
    }
  }, [numPage, uuidPost])

  const buttonL = () => {
    setCity('')
    setName('')
    setCpf('')
    setNumPage(1)
    getPatients(uuidPost, 1, '', '', '')
  }

  const handleCancel = useCallback(
    async (uuid: string) => {
      setLoading(true)
      const response = await cancelSchedule(uuid)
      if (response.status) {
        setLoading(false)
        alertSuccess('Consulta cancelada')
        const newPatients = patients?.filter((p) => {
          return p.uuid !== uuid
        })
        setPatients(newPatients)
        setModal('')
      } else {
        setLoading(false)
        alertDanger('Não foi possível cancelar a consulta')
      }
    },
    [patients]
  )

  const ModalConfirm = () => {
    return (
      <S.ModalContainer>
        <S.ModalContent>
          <Text style={{ marginBottom: '20px' }}>
            Deseja cancelar a consulta?
          </Text>
          <S.ButtonsContainer>
            <Button
              type="button"
              color="primary-light"
              size="small"
              styles={{ marginRight: '10px' }}
              onClick={() => handleCancel(modal)}
            >
              Sim
            </Button>
            <Button
              type="button"
              color="primary-light"
              size="small"
              onClick={() => setModal('')}
            >
              Não
            </Button>
          </S.ButtonsContainer>
        </S.ModalContent>
      </S.ModalContainer>
    )
  }

  return (
    <Global.Container>
      {loading && <Loading title="Aguarde..." />}
      <Header />
      {modal && <ModalConfirm />}
      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Card>
          <S.ContainerFilter>
            <Text size={24} weight="semi-bold" style={{ marginRight: '20px' }}>
              Fila de atendimento
            </Text>
            <div>
              <Search
                name="search-name"
                placeholder="Filtrar pelo nome..."
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
              <Search
                name="search-cpf"
                placeholder="Filtrar pelo cpf..."
                value={cpfCnpjMask(cpf)}
                maxLength={14}
                onChange={(e: any) => setCpf(cpfCnpjMask(e.target.value))}
              />
              <Search
                name="search-city"
                placeholder="Filtrar pelo município..."
                value={city}
                onChange={(e: any) => setCity(e.target.value)}
              />
            </div>
            <Button
              type="button"
              color="primary-light"
              onClick={() => getPatients(uuidPost, 1)}
              size="small"
              styles={{ marginLeft: 20 }}
            >
              Buscar
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={buttonL}
              size="small"
              styles={{ marginLeft: 20 }}
            >
              Limpar
            </Button>
          </S.ContainerFilter>
        </Card>
        <Card>
          <S.TablePatients>
            <Table className="table-content">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Data da consulta</TableCell>
                  <TableCell>Horário</TableCell>
                  <TableCell>Especialidade</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Município</TableCell>
                  <TableCell>Tipo da consulta</TableCell>
                  <TableCell width="13%">Exames</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {patients &&
                  patients?.map((patient: any) => (
                    <TableRow key={patient.uuid}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="primary-col"
                      >
                        {patient.name}
                      </TableCell>
                      <TableCell align="left">
                        {patient.schedulingDate || ''}
                      </TableCell>
                      <TableCell align="left">
                        {patient.schedulingTime}
                      </TableCell>
                      <TableCell align="left">
                        {patient.areaDescription}
                      </TableCell>
                      <TableCell align="left">
                        {telephoneMask(patient.cellphone || '')}
                      </TableCell>
                      <TableCell align="left">{patient.city}</TableCell>
                      <TableCell align="left">
                        {patient.is_return ? 'Retorno' : 'Normal'}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          color="outline-primary"
                          onClick={() => window.open(patient.url, '_blank')}
                          size="small"
                        >
                          Subir exames
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          color="primary-light"
                          onClick={() => setModal(patient.uuid)}
                          size="small"
                          styles={{ border: 'none' }}
                        >
                          Cancelar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {patients?.length !== 0 && maxPage !== 1 && (
              <S.Pagination>
                <Pagination
                  count={maxPage}
                  color="secondary"
                  page={numPage}
                  onChange={(e, v) => setNumPage(v)}
                />
              </S.Pagination>
            )}
          </S.TablePatients>
        </Card>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}
