import React from 'react'
import api from '../../configs/api'

export const initVideoCall = async (
  data: {
    identity: string
    room: string
  },
  state: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setVideoToken: React.Dispatch<React.SetStateAction<string | null>>,
  setState: React.Dispatch<React.SetStateAction<any>>
) => {
  setState({ ...state, message: 'Criando sala de video...' })
  setLoading(true)

  try {
    const response = await api.post('/video/v1/token', data)

    setVideoToken(response?.data?.token)

    setLoading(false)
    setState({ ...state, message: '' })
  } catch (err) {
    setVideoToken(null)
    setLoading(false)
    setState({ ...state, message: '' })
  }
}
interface ScheduleProps {
  uuid: string
}

export const verifySchedule = async ({ uuid }: ScheduleProps) => {
  try {
    const response = await api.get(
      `/schedulings/v1/clinics/appointments/user/${uuid}`
    )

    return {
      status: true,
      data: response?.data?.items,
    }
  } catch (err) {
    return {
      status: false,
    }
  }
}
