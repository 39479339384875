import axios from 'axios'
import { alertDanger } from '../utils/toast'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_URL,
  timeout: 30000,
})

api.interceptors.request.use(
  async (config: any) => {
    const token = window.localStorage.getItem('token')
    const tokenPatient = window.localStorage.getItem('tokenPatient')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else if (tokenPatient) {
      config.headers.Authorization = `Bearer ${tokenPatient}`
    } else {
      config.headers.Authorization = `Basic ${process.env.REACT_APP_API_KEY}`
    }

    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    const { status } = error.response

    if ([401, 403].includes(status)) {
      window.localStorage.clear()
      alertDanger('Sua sessão expirou! Faça login novamente.')

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }

    return Promise.reject(error)
  }
)

export default api
