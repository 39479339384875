import React, { useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  TextField as Input,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  cepMask,
  cepUnmask,
  cpfCnpjMask,
  dateMask,
  telephoneMask,
  telephoneUnmask,
} from 'js-essentials-functions'
import moment from 'moment'
import { Button, Text } from '../../components'
import * as S from './styles'
import { FormProps } from './interfaces'
import { createPatient, createScheduling, updateDatasPatient } from './services'
import { useScheduling } from '../../context/scheduling/scheduling.context'
import { dateMaskUS, maskHeight, maskWeight, numUs } from '../../utils/mask'
import { alertDanger } from '../../utils/toast'
import { states } from '../../utils/Uf'

const schemaRegister = yup.object().shape({
  name: yup.string().required('Informe o seu email!'),
  birthday: yup.string().required('Informe sua data de nascimento!'),
  cellphone: yup.string().required('Informe o seu telefone!'),
  email: yup
    .string()
    .required('Informe o seu email!')
    .email('Informe um email valido!'),
  zip: yup.string().required('Informe o CEP!'),
  street: yup.string().required('Informe a rua!'),
  number: yup.string().required('Informe o número!'),
  city: yup.string().required('Informe a cidade!'),
  state: yup.string().required('Informe o estado!'),
  neighborhood: yup.string().required('Informe o bairro!'),
  complement: yup.string(),
  gender: yup.string().required('Informe o seu gênero!'),
  weight: yup.string(),
  height: yup.string(),
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0, 1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const genders = [
  { uuid: 'male', name: 'Masculino' },
  { uuid: 'female', name: 'Feminino' },
  { uuid: 'other', name: 'Outros' },
]

const Form: React.FunctionComponent<FormProps> = ({
  handleModal,
  datas,
  handleCancelDatas,
  newRegister,
  setLoading,
}) => {
  const { scheduling } = useScheduling()
  const classes = useStyles()
  const initialValues = useMemo(() => {
    return {
      name: '',
      birthday: '',
      cellphone: '',
      email: '',
      zip: '',
      street: '',
      number: '',
      document: '',
      city: '',
      state: '',
      neighborhood: '',
      complement: '',
      gender: '',
      weight: '',
      height: '',
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schemaRegister,
    onSubmit: async (values: any) => {
      const valores = {
        ...values,
        cellphone: telephoneUnmask(values.cellphone),
        zip: cepUnmask(values.zip),
        birthday: dateMaskUS(values.birthday),
        document: datas?.user.document || newRegister.cpf,
        height: values.height ? numUs(values.height) : '0',
        weight: values.weight ? numUs(values.weight) : '0',
      }
      setLoading(true)

      if (newRegister && newRegister.status) {
        const response = await createPatient({ patient: valores })
        if (response.status) {
          const resCreated = await createScheduling({
            userUuid: response.uuidUser || '',
            slotId: scheduling.timeId,
            heathPostUuid: scheduling.uuidPost || '',
            returnId: scheduling.returnId,
          })
          if (resCreated.status) {
            setLoading(false)
            handleModal()
          } else {
            setLoading(false)
            alertDanger(resCreated.message || 'Não foi possivel agendar')
          }
        } else {
          setLoading(false)
          alertDanger('Erro ao cadastrar o paciente')
        }
      } else {
        const response = await updateDatasPatient({
          patient: valores,
          uuid: datas?.user.uuid,
        })

        if (response.status) {
          const resCreated = await createScheduling({
            userUuid: datas?.user.uuid || '',
            slotId: scheduling.timeId,
            heathPostUuid: scheduling.uuidPost || '',
            returnId: scheduling.returnId,
          })
          console.log(resCreated)
          if (resCreated.status) {
            setLoading(false)
            handleModal()
          } else {
            setLoading(false)
            alertDanger(resCreated.message || 'Não foi possivel agendar')
          }
        } else {
          setLoading(false)
          alertDanger('Não foi possivel agendar')
        }
      }
    },
  })

  const getAddress = async (cep: string) => {
    setLoading(true)
    fetch(`https://api.pagar.me/1/zipcodes/${cep}`, {
      method: 'get',
    })
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
      })
      .then((res) => {
        setLoading(false)
        formik.setValues((v: any) => ({
          ...v,
          zip: cep,
          street: res.street || '',
          neighborhood: res.neighborhood || '',
          city: res.city || '',
          state: res.state || '',
        }))
      })
      .catch(() => {
        setLoading(false)
        formik.setValues((v: any) => ({
          ...v,
          zip: cep,
        }))
      })
  }

  const handleUpdateAddress = (cep: string) => {
    if ((cepUnmask(cep) || cep === '') && cepUnmask(cep).length < 8) {
      formik.setValues((v: any) => ({ ...v, zip: cep }))
    } else {
      getAddress(cepUnmask(cep))
    }
  }

  const setDataUser = () => {
    formik.setValues({
      document: datas?.user.document || '',
      name: datas?.user.name || '',
      birthday: moment(datas?.user.birthday).format('DD/MM/YYYY') || '',
      cellphone: datas?.user.cellphone || '',
      email: datas?.user.email || '',
      zip: datas?.address.zip || '',
      street: datas?.address.street || '',
      number: datas?.address.number || '',
      city: datas?.address.city || '',
      state: datas?.address.state || '',
      neighborhood: datas?.address.neighborhood || '',
      complement: datas?.address.complement || '',
      gender: datas?.user.gender || '',
      weight: maskWeight(datas?.user.weight) || '',
      height: maskHeight(datas?.user.height) || '',
    })
  }
  useEffect(() => {
    if (datas) setDataUser()
    else {
      formik.resetForm()
    }
  }, [datas])

  useEffect(() => {
    if (newRegister) {
      formik.setValues({
        ...initialValues,
        document: newRegister.cpf,
      })
    }
  }, [newRegister])

  const handleCancel = () => {
    formik.resetForm()
    handleCancelDatas()
  }

  return (
    <div>
      <Text style={{ marginBottom: '20px' }} size={20} weight="semi-bold">
        {newRegister ? 'Novo Cadastro' : 'Atualizar Cadastro'}
      </Text>
      <Text style={{ marginBottom: '20px' }} size={16} weight="regular">
        Dados Pessoais
      </Text>
      <S.Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container>
          <Grid
            item
            sm={8}
            style={{ marginRight: 15 }}
            className={classes.paper}
          >
            <Input
              label="CPF"
              placeholder=""
              type="text"
              name="document"
              variant="outlined"
              className="input-default"
              color="secondary"
              disabled
              autoComplete="not"
              onChange={formik.handleChange}
              value={cpfCnpjMask(formik.values.document)}
              error={!!formik.errors.document}
              helperText={formik.errors.document}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              label="Nome Completo"
              placeholder="Informe seu nome"
              type="text"
              name="name"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              disabled={!formik?.values?.document}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!formik.errors.name && formik.touched.name}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Data de Nascimento"
              placeholder="Informe sua data de nascimento"
              type="text"
              disabled={!formik?.values?.document}
              name="birthday"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              inputProps={{ maxLength: 10 }}
              onChange={formik.handleChange}
              value={dateMask(formik.values.birthday)}
              error={!!formik.errors.birthday && formik.touched.birthday}
              helperText={formik.touched.birthday && formik.errors.birthday}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Celular"
              placeholder="Informe seu celular"
              disabled={!formik?.values?.document}
              type="text"
              name="cellphone"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              inputProps={{ maxLength: 15 }}
              onChange={formik.handleChange}
              value={telephoneMask(formik.values.cellphone)}
              error={!!formik.errors.cellphone && formik.touched.cellphone}
              helperText={formik.touched.cellphone && formik.errors.cellphone}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Email"
              placeholder="Informe seu email"
              disabled={!formik?.values?.document}
              type="email"
              name="email"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={!!formik.errors.email && formik.touched.email}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="CEP"
              disabled={!formik?.values?.document}
              placeholder="Informe seu cep"
              type="text"
              name="zip"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              inputProps={{ maxLength: 9 }}
              onChange={(e) => handleUpdateAddress(e.target.value)}
              value={cepMask(formik.values.zip)}
              error={!!formik.errors.zip && formik.touched.zip}
              helperText={formik.touched.zip && formik.errors.zip}
            />
          </Grid>
          <Grid item sm={8} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Endereço"
              placeholder="Informe seu endereço"
              type="text"
              name="street"
              variant="outlined"
              className="input-default"
              disabled={!formik?.values?.document}
              color="secondary"
              autoComplete="not"
              onChange={formik.handleChange}
              value={formik.values.street}
              error={!!formik.errors.street && formik.touched.street}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item sm={4} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Número"
              placeholder="Número"
              type="text"
              disabled={!formik?.values?.document}
              name="number"
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              onChange={formik.handleChange}
              value={formik.values.number}
              error={!!formik.errors.number && formik.touched.number}
              helperText={formik.touched.number && formik.errors.number}
            />
          </Grid>
          <Grid item sm={5} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Cidade"
              placeholder="Informe a cidade"
              type="text"
              name="city"
              variant="outlined"
              className="input-default"
              color="secondary"
              disabled={!formik?.values?.document}
              autoComplete="not"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={!!formik.errors.city && formik.touched.city}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item sm={3} className={classes.paper}>
            <FormControl
              variant="outlined"
              style={{ display: 'flex', margin: '5px 0' }}
              error={!!formik.touched.state && !!formik.errors.state}
            >
              <InputLabel color="secondary" id="outlined-basic">
                Estado
              </InputLabel>
              <Select
                style={{
                  fontSize: '12px',
                  textAlign: 'left',
                }}
                disabled={!formik?.values?.document}
                id="outlined-basic"
                label="Estado"
                name="state"
                color="secondary"
                onChange={formik.handleChange}
                value={formik.values.state}
                error={!!formik.errors.state && formik.touched.state}
              >
                {states.map((g) => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.state && formik.errors.state && (
                <FormHelperText>{formik.errors.state}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item sm={4} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Bairro"
              placeholder="Informe o bairro"
              type="text"
              name="neighborhood"
              disabled={!formik?.values?.document}
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              onChange={formik.handleChange}
              value={formik.values.neighborhood}
              error={
                !!formik.errors.neighborhood && formik.touched.neighborhood
              }
              helperText={
                formik.touched.neighborhood && formik.errors.neighborhood
              }
            />
          </Grid>
          <Grid item sm={12}>
            <Grid item sm={5} className={classes.paper}>
              <Input
                id="outlined-basic"
                disabled={!formik?.values?.document}
                label="Complemento"
                placeholder="Complemento"
                type="text"
                name="complement"
                variant="outlined"
                className="input-default"
                color="secondary"
                autoComplete="not"
                onChange={formik.handleChange}
                value={formik.values.complement}
                error={!!formik.errors.complement}
              />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid item sm={5} className={classes.paper}>
              <FormControl
                variant="outlined"
                disabled={!formik?.values?.document}
                style={{ display: 'flex', margin: '5px 0' }}
                error={!!formik.touched.height && !!formik.errors.gender}
              >
                <InputLabel color="secondary" id="outlined-basic">
                  Gênero
                </InputLabel>
                <Select
                  style={{
                    fontSize: '12px',
                    textAlign: 'left',
                  }}
                  id="outlined-basic"
                  label="Gênero"
                  name="gender"
                  color="secondary"
                  onChange={formik.handleChange}
                  autoComplete="not"
                  value={formik.values.gender}
                  error={!!formik.errors.gender && formik.touched.height}
                >
                  {genders.map((g) => (
                    <MenuItem key={g.uuid} value={g.uuid}>
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.height && formik.errors.gender && (
                  <FormHelperText>{formik.errors.gender}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item sm={5} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Peso (kg)"
              placeholder="Informe seu peso"
              type="text"
              name="weight"
              disabled={!formik?.values?.document}
              variant="outlined"
              className="input-default"
              color="secondary"
              autoComplete="not"
              inputProps={{ maxLength: 5 }}
              onChange={formik.handleChange}
              value={formik.values.weight}
              error={!!formik.errors.weight && formik.touched.height}
              helperText={formik.touched.height && formik.errors.weight}
            />
          </Grid>
          <Grid item sm={5} className={classes.paper}>
            <Input
              id="outlined-basic"
              label="Altura (m)"
              placeholder="Informe sua altura"
              type="text"
              name="height"
              variant="outlined"
              className="input-default"
              color="secondary"
              disabled={!formik?.values?.document}
              autoComplete="not"
              onChange={formik.handleChange}
              inputProps={{ maxLength: 4 }}
              value={maskHeight(formik.values.height)}
              error={!!formik.errors.height && formik.touched.height}
              helperText={formik.touched.height && formik.errors.height}
            />
          </Grid>
        </Grid>

        <S.ButtonsContainer>
          <Button
            type="button"
            color="link-danger"
            size="small"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            size="small"
            styles={{ 'margin-left': '10px' }}
          >
            Confirmar
          </Button>
        </S.ButtonsContainer>
      </S.Form>
    </div>
  )
}

export default Form
