import React from 'react'
import { ThemeProvider as ThemeStyled } from 'styled-components'
import { ThemeProvider } from '@material-ui/core'
// Theme
import theme from './theme/index.theme'
import light from './theme/light'
import GlobalStyle from './global/styles.global'
import Routes from './routes'
import { SignInProvider } from './context/authentication/signin.context'

function App() {
  const habitual =
    process.env.NODE_ENV !== 'development' &&
    window.location.host === 'habitual.starbem.app'

  if (habitual) {
    window.document.title = 'Habitual Saúde - Telemedicina'
    const link =
      window.document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.setAttribute('href', '/habitual.ico')
  }
  return (
    <SignInProvider>
      <ThemeProvider theme={theme}>
        <ThemeStyled theme={light}>
          <Routes habitual={habitual} />
          <GlobalStyle />
        </ThemeStyled>
      </ThemeProvider>
    </SignInProvider>
  )
}

export default App
