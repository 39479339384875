// @ts-nocheck
import React from 'react'
import Text from '../../Text'
import Room from '../Room'

interface VideoChatTypes {
  roomName: string
  videoToken: string | null
  handleLogout: () => void
  schedule: any
}

const VideoChat: React.FunctionComponent<VideoChatTypes> = ({
  roomName,
  videoToken,
  handleLogout,
  schedule,
}) => {
  let render

  if (videoToken) {
    render = (
      <Room
        roomName={roomName}
        token={videoToken}
        handleLogout={handleLogout}
      />
    )
  } else {
    render = (
      <div>
        {schedule ? (
          <Text weight="semi-bold" size={20}>
            Inicie sua chamada!
          </Text>
        ) : (
          <Text weight="semi-bold" size={20}>
            Agende uma consulta!
          </Text>
        )}
      </div>
    )
  }

  return render
}

export default VideoChat
