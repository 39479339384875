import React, { useEffect, useMemo } from 'react'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import { TextField as Input, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { cpfCnpjMask, dateMask, telephoneMask } from 'js-essentials-functions'
import { Button, Text } from '../../components'
import * as S from './styles'
import { FormProps } from './interfaces'
import { createScheduling, registerUserB2b } from './services'
import { useScheduling } from '../../context/scheduling/scheduling.context'
import { alertDanger } from '../../utils/toast'

const schemaLogin = yup.object().shape({
  name: yup.string().required('Informe o seu email!'),
  birthday: yup.string().required('Informe sua data de nascimento!'),
  cellphone: yup.string().required('Informe o seu telefone!'),
  email: yup
    .string()
    .required('Informe o seu email!')
    .email('Informe um email valido!'),
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0, 1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

const FormB2b: React.FunctionComponent<FormProps> = ({
  handleModal,
  datas,
  handleCancelDatas,
  newRegister,
  setLoading,
}) => {
  const { scheduling } = useScheduling()
  const classes = useStyles()
  const initialValues = useMemo(() => {
    return {
      name: '',
      birthday: '',
      cellphone: '',
      email: '',
      document: '',
    }
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: async (values: any) => {
      const valores = {
        ...values,
        document: datas?.user.document || newRegister.cpf,
      }
      setLoading(true)
      const storage = window.localStorage.getItem('@StarBem-web:user')
      if (storage) console.log(JSON.parse(storage).user?.national_registration)
      if (newRegister && newRegister.status) {
        const response = await registerUserB2b({
          nationalRegistration: storage
            ? JSON.parse(storage).user?.national_registration
            : '',
          user: {
            ...valores,
          },
        })
        if (response.status) {
          const resCreated = await createScheduling({
            userUuid: response.uuid,
            slotId: scheduling.timeId,
            heathPostUuid: scheduling.uuidPost || '',
            returnId: scheduling.returnId,
          })
          if (resCreated.status) {
            setLoading(false)
            handleModal()
          } else {
            setLoading(false)
            alertDanger(resCreated.message || 'Não foi possivel agendar')
          }
        } else {
          alertDanger('Erro ao cadastrar o paciente')
          setLoading(false)
        }
      } else {
        const resCreated = await createScheduling({
          userUuid: datas?.user.uuid || '',
          slotId: scheduling.timeId,
          heathPostUuid: scheduling.uuidPost || '',
          returnId: scheduling.returnId,
        })
        if (resCreated.status) {
          setLoading(false)
          handleModal()
        } else {
          setLoading(false)
          alertDanger(resCreated.message || 'Não foi possivel agendar')
        }
      }
    },
  })

  const setDataUser = () => {
    formik.setValues({
      document: datas?.user.document || '',
      name: datas?.user.name || '',
      birthday: datas?.user.birthday || '',
      cellphone: datas?.user.cellphone || '',
      email: datas?.user.email || '',
    })
  }
  useEffect(() => {
    if (datas) setDataUser()
    else {
      formik.resetForm()
    }
  }, [datas])

  useEffect(() => {
    if (newRegister) {
      formik.setValues({
        ...initialValues,
        document: newRegister.cpf,
      })
    }
  }, [newRegister])

  const handleCancel = () => {
    formik.resetForm()
    handleCancelDatas()
  }

  return (
    <div>
      <Text style={{ marginBottom: '20px' }} size={20} weight="semi-bold">
        {newRegister ? 'Novo cadastro' : 'Agendar'}
      </Text>
      <Text style={{ marginBottom: '20px' }} size={16} weight="regular">
        Dados Pessoais
      </Text>
      <FormikProvider value={formik}>
        <S.Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid
              item
              sm={8}
              style={{ marginRight: 15 }}
              className={classes.paper}
            >
              <Input
                id="outlined-basic"
                label="CPF"
                placeholder=""
                type="text"
                name="document"
                variant="outlined"
                className="input-default"
                color="secondary"
                disabled
                onChange={formik.handleChange}
                value={cpfCnpjMask(formik.values.document)}
                error={!!formik.errors.document}
                helperText={formik.errors.document}
              />
            </Grid>
            <Grid item sm={8} className={classes.paper}>
              <Input
                id="outlined-basic"
                label="Nome Completo"
                placeholder="Informe seu nome"
                type="text"
                name="name"
                variant="outlined"
                className="input-default"
                color="secondary"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={!!formik.errors.name && formik.touched.name}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item sm={8} className={classes.paper}>
              <Input
                id="outlined-basic"
                label="Data de Nascimento"
                placeholder="Informe sua data de nascimento"
                type="text"
                name="birthday"
                variant="outlined"
                className="input-default"
                color="secondary"
                inputProps={{ maxLength: 10 }}
                onChange={formik.handleChange}
                value={dateMask(formik.values.birthday)}
                error={!!formik.errors.birthday && formik.touched.birthday}
                helperText={formik.touched.birthday && formik.errors.birthday}
              />
            </Grid>
            <Grid item sm={8} className={classes.paper}>
              <Input
                id="outlined-basic"
                label="Celular"
                placeholder="Informe seu celular"
                type="text"
                name="cellphone"
                variant="outlined"
                className="input-default"
                color="secondary"
                inputProps={{ maxLength: 15 }}
                onChange={formik.handleChange}
                value={telephoneMask(formik.values.cellphone)}
                error={!!formik.errors.cellphone && formik.touched.cellphone}
                helperText={formik.touched.cellphone && formik.errors.cellphone}
              />
            </Grid>
            <Grid item sm={8} className={classes.paper}>
              <Input
                id="outlined-basic"
                label="Email"
                placeholder="Informe seu email"
                type="email"
                name="email"
                variant="outlined"
                className="input-default"
                color="secondary"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={!!formik.errors.email && formik.touched.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>

          <S.ButtonsContainer>
            <Button
              type="button"
              color="link-danger"
              size="small"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              color="primary"
              size="small"
              styles={{ 'margin-left': '10px' }}
            >
              Confirmar
            </Button>
          </S.ButtonsContainer>
        </S.Form>
      </FormikProvider>
    </div>
  )
}

export default FormB2b
