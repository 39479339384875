import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem, Button, Fade } from '@material-ui/core'

import { useSignIn } from '../../context/authentication/signin.context'
import './styles.scss'
import hamburger from '../../assets/icons/hamgurger.png'
import logoutIcon from '../../assets/icons/log-out.svg'

interface Props {
  type: any
}

const MenuMobile = ({ type }: Props) => {
  const history = useHistory()
  const { logout } = useSignIn()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    history.replace('/')
  }

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        id="menu-mobile"
        onClick={handleClick}
      >
        <img
          style={{ width: 30, height: 30 }}
          src={hamburger}
          alt="Hamburger"
        />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!type && (
          <MenuItem onClick={() => history.push('/dashboard')}>Agenda</MenuItem>
        )}
        {!type && (
          <MenuItem onClick={() => history.push('/pacientes')}>Fila</MenuItem>
        )}
        {!type && (
          <MenuItem onClick={() => history.push('/historico')}>
            Histórico
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          Sair
          <img
            style={{ marginLeft: 10, width: 20, height: 20 }}
            src={logoutIcon}
            alt="Hamburger"
          />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default MenuMobile
