import React, { useEffect, useState } from 'react'
import './styles.scss'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
  Box,
} from '@material-ui/core'
import moment from 'moment'
import { Button, Text, Loading } from '../index'
import api from '../../configs/api'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1200,
  width: '100%',
  minHeight: 500,
  // height: '100%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  overflow: 'hidden',
  p: 4,
}

interface ModalShowProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalOpen: boolean
  setUserReturn: any
}

const ModalReturn: React.FunctionComponent<ModalShowProps> = ({
  setModalOpen,
  modalOpen,
  setUserReturn,
}) => {
  const [users, setUsers] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const itemStorage = window.localStorage.getItem('@StarBem-web:user')
  const getUsers = async () => {
    if (!itemStorage) return
    const uuidPost = JSON.parse(itemStorage)

    try {
      setLoading(true)
      const res = await api.get(
        `/sus/scheduling/health-post/${uuidPost?.uuid}/return`
      )

      setUsers(res.data.returns)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (itemStorage) {
      getUsers()
    }
  }, [])

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ overflow: 'overlay' }}>
        <div className="card-header">
          <Text weight="semi-bold" size={20}>
            Retornos Disponíveis
          </Text>
          <Button
            type="button"
            color="primary"
            size="small"
            onClick={() => {
              setModalOpen(false)
            }}
          >
            X
          </Button>
        </div>
        {loading && <Loading title="Aguarde..." />}
        <Table className="table-content">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Data da consulta</TableCell>
              <TableCell>Horário</TableCell>
              <TableCell>Especialidade</TableCell>
              <TableCell>Data de expiração</TableCell>
              <TableCell>Retorno</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user: any) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row" className="primary-col">
                  {user.name}
                </TableCell>
                <TableCell align="left">
                  {moment(user.scheduling_date || '').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="left">
                  {moment(user.scheduling_date || '')
                    .utc()
                    .format('HH:mm')}{' '}
                </TableCell>
                <TableCell align="left">{user.area_name}</TableCell>
                <TableCell align="left">
                  {moment(user.expired_date || '').format('DD/MM/YYYY')}{' '}
                </TableCell>
                <TableCell align="left">
                  <Button
                    type="button"
                    color="outline-primary"
                    size="small"
                    onClick={() => {
                      setUserReturn({
                        returnId: user.id,
                        name: user.name,
                        specialty: user.area_name,
                        userDocument: user.document,
                        isReturn: true,
                      })
                      setModalOpen(false)
                    }}
                    styles={{ marginTop: 10, marginRight: 5 }}
                  >
                    Agendar retorno
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* {schedulings?.length !== 0 && maxPages !== 1 && (
                <S.Pagination>
                  <Pagination
                    count={10}
                    color="secondary"
                    page={1}
                    onChange={(e, v) => setNumPage(v)}
                  />
                </S.Pagination>
              )} */}
      </Box>
    </Modal>
  )
}

export default ModalReturn
