import React from 'react'
import './styles.scss'

import { useHistory } from 'react-router-dom'
import { Button } from '../index'
import Text from '../Text'

interface ModalShowProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalHaveReturn: React.FunctionComponent<ModalShowProps> = ({
  setModalOpen,
}) => {
  const history = useHistory()
  function handleSubmit() {
    setModalOpen(false)
    history.push('/dashboard')
  }

  return (
    <>
      (
      <div className="modal-content">
        <div className="modal-container">
          <Text style={{ marginBottom: '25px' }} weight="semi-bold" size={24}>
            Retorno pendente
          </Text>
          <Text
            style={{ marginBottom: '10px', textAlign: 'start' }}
            weight="regular"
            size={18}
          >
            Esse paciente possui um agendamento de retorno pendente.
          </Text>
          <Text
            style={{ marginBottom: '25px', textAlign: 'start' }}
            weight="regular"
            size={18}
          >
            {`Caso deseje realizar o agendamento de retorno selecione "Pacientes
            com retorno" na página inicial e siga o fluxo de agendamento.`}
          </Text>
          <div className="content-buttons">
            <Button
              type="button"
              color="outline-primary"
              // @ts-ignore
              onClick={() => setModalOpen(false)}
              styles={{ marginTop: 10, width: '100%', marginRight: 5 }}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              color="primary"
              // @ts-ignore
              onClick={() => handleSubmit()}
              styles={{ marginTop: 10, width: '100%', marginRight: 5 }}
            >
              Agendar Retorno
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalHaveReturn
