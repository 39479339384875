import React, { useState, useCallback, useEffect } from 'react'
import { Grid } from '@material-ui/core'
// Components
import moment from 'moment'
import { Card, Footer, Header, Loading, Text, Button } from '../../components'

// Styles
import VideoChat from '../../components/VideoChat/Video'
import * as Global from '../../global/styles.global'
import * as S from './styles'
import avatar from '../../assets/images/avatar.jpg'
import cam from '../../assets/icons/cam.png'

import { initVideoCall, verifySchedule } from './services'

const Calls = () => {
  const [videoToken, setVideoToken] = useState<string | null>(null)
  const [schedule, setSchedule] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    showModal: false,
    message: '',
    url: '',
  })

  const handleInitVideo = useCallback(() => {
    initVideoCall(
      {
        identity: schedule?.doctor.id,
        room: schedule?.scheduling.id,
      },
      state,
      setLoading,
      setVideoToken,
      setState
    )
  }, [schedule])

  const getSchedule = async () => {
    setLoading(true)
    const dataUser = window.localStorage.getItem('@StarBem-web:user')
    if (dataUser) {
      const uuidUser = JSON.parse(dataUser)

      const schedulePendent = await verifySchedule({
        uuid: uuidUser?.id,
      })
      const scheduleStarted = await verifySchedule({
        uuid: uuidUser?.id,
      })

      setLoading(false)
      const schedules = [...schedulePendent.data, ...scheduleStarted.data]

      const newSchedule = schedules
        .filter(
          (sc) =>
            moment(sc.scheduling.from_date_time).format('DD/MM/yyyy') ===
              moment(new Date()).format('DD/MM/yyyy') ||
            new Date(sc.scheduling.from_date_time) >= new Date()
        )
        .sort((a, b) => {
          if (a.scheduling.from_date_time < b.scheduling.from_date_time)
            return -1
          return 1
        })

      if (newSchedule.length !== 0) {
        setSchedule(newSchedule[0])
      } else {
        setSchedule(null)
      }
    }
  }

  useEffect(() => {
    getSchedule()
  }, [])

  const handleFinishVideo = useCallback(() => {
    setVideoToken(null)
  }, [schedule, setVideoToken])
  const disabledButton = schedule
    ? moment(schedule?.scheduling?.scheduling_date).format('DD/MM') !==
      moment(new Date()).format('DD/MM')
    : true
  return (
    <Global.Container>
      {loading && <Loading title={state.message} />}
      <Header type="paciente" />

      <S.GridContent
        container
        xl={12}
        style={{ maxWidth: 1600, margin: '0 auto' }}
      >
        <Grid item xs={12} md={3} style={{ padding: '2px ' }}>
          {schedule ? (
            <Card className="p-0 m-0">
              <S.DetailsDoctor>
                <Text size={22} weight="semi-bold">
                  Médico(a)
                </Text>
                <div className="content-photo">
                  <img src={schedule?.doctor?.img || avatar} alt="Avatar" />
                </div>
                <S.Details>
                  <Text size={14} color="#525166" weight="regular">
                    {`Dr.(a) ${schedule?.doctor?.name}`}
                  </Text>
                  <Text size={14} color="#525166" weight="regular">
                    {schedule?.doctor?.document}
                  </Text>
                  <Text size={13} weight="regular" style={{ marginTop: 10 }}>
                    {`Data: ${moment(schedule?.scheduling?.from_date_time)
                      .utc()
                      .format('DD/MM')}`}
                  </Text>
                  <Text size={13} weight="regular">
                    {`Horário: ${moment(schedule?.scheduling?.from_date_time)
                      .utc()
                      .format('HH:mm')}`}
                  </Text>
                </S.Details>
              </S.DetailsDoctor>
            </Card>
          ) : (
            <Card>
              <S.DetailsDoctor>
                <Text size={22} weight="semi-bold">
                  Nenhuma consulta agendada!
                </Text>
              </S.DetailsDoctor>
            </Card>
          )}
          <Card>
            <S.DetailsExams style={{ justifyContent: 'space-between' }}>
              <Text size={18} weight="semi-bold">
                Exames
              </Text>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  window.open(schedule.scheduling.url, '_blank')
                }}
                styles={{ width: '100%' }}
                disabled={!schedule}
              >
                Enviar exames
              </Button>
            </S.DetailsExams>
          </Card>
        </Grid>
        <Grid item xs={12} md={9} style={{ padding: '2px 6px' }}>
          <Card>
            {videoToken ? (
              <S.VideoContainer>
                <VideoChat
                  roomName={schedule?.scheduling?.uuid}
                  videoToken={videoToken}
                  handleLogout={handleFinishVideo}
                  schedule={schedule}
                />
              </S.VideoContainer>
            ) : (
              <S.VideoContainer>
                <Text size={18} weight="semi-bold">
                  Videochamada
                </Text>
                <S.OpenVideo>
                  <S.VideoImage>
                    <img src={cam} alt="Camera" />
                  </S.VideoImage>
                  <Button
                    color="primary"
                    type="button"
                    disabled={disabledButton}
                    onClick={handleInitVideo}
                    styles={{ width: '100%' }}
                  >
                    Entrar na videochamada
                  </Button>
                </S.OpenVideo>
              </S.VideoContainer>
            )}
          </Card>
        </Grid>
      </S.GridContent>
      <Footer />
    </Global.Container>
  )
}

export default Calls
