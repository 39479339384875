import moment from 'moment'
import api from '../../../configs/api'

export interface SchedulingDatas {
  uuid?: string
  namePatient: string
  dateScheduling: string
  timeScheduling: string
  tellphone: string
  specialty: string
  city: string
  state: string
  nameDoctor: string
  documentDoctor: string
  exams: string
}
interface Props {
  uuidPost: string
  page: number
  limit: number
  start: string
  end: string
  status: string
}

export const servicesGetSchedulings = async ({
  uuidPost,
  page,
  limit,
  start,
  end,
  status,
}: Props) => {
  try {
    const response = await api.get(
      `/schedulings/v1/clinics/${uuidPost}/historic?page=${page}&limit=${limit}&start=${start}&end=${end}&status=${status}`
    )

    const { items, total } = response?.data

    const datas: SchedulingDatas[] = items.map((item: any) => ({
      namePatient: item.user_name,
      uuid: `${item.user_name}${item.date}`,
      tellphone: item.cellphone,
      city: item.city,
      specialty: item.specialty_name,
      dateScheduling: item.date ? moment(item.date).format('DD/MM/YYYY') : '',
      timeScheduling: item.date ? moment(item.date).utc().format('HH:mm') : '',
      state: item.state,
      is_return: item.return_id ? 'Retorno' : 'Normal',
      status:
        (item.status === 'finished' && 'Finalizado') ||
        (item.status === 'canceled' && 'Cancelado') ||
        (item.status === 'started' && 'Iniciado'),
      nameDoctor: item.doctor.name,
      documentDoctor: `${item.doctor.professional_document_type} ${item.doctor.professional_document_number} ${item.doctor.professional_document_uf}`,
      exams: item.exams,
    }))

    return {
      status: true,
      datas,
      maxPages: Math.ceil(total / limit),
    }
  } catch (err) {
    console.log(err)

    return {
      status: false,
      message: 'Não foi possível retornar os agendamentos',
    }
  }
}
