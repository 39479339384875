import api from '../../configs/api'

export const getDoctors = async (
  date: string,
  occupation: string,
  nationalRegistration: string
) => {
  try {
    console.log(nationalRegistration)

    /* eslint-disable */
    const res = await api.post(`/schedulings/v2/verify-doctors`, {
      specialty_id: occupation,
      scheduling_date: date,
    })
    const items = res.data.doctors

    const doctors = items.map((item: any) => ({
      name: item.name,
      img: item.img,
      uuid: item.id,
      document: item.document,
    }))

    return {
      status: true,
      doctors: doctors,
    }
  } catch (err: any) {
    return {
      status: false,
      message:
        err?.response?.data?.message ||
        'Não foi possível obter a lista de médicos.',
    }
  }
}

export interface SelectTimeProps {
  date: string
  uuidDoctor: string
  uuidArea: string
}

export const selectTime = async ({
  date,
  uuidDoctor,
  uuidArea,
}: SelectTimeProps) => {
  try {
    const res = await api.post(`schedulings/v1/verify-time`, {
      specialty_id: uuidArea,
      doctor_id: uuidDoctor,
      scheduling_date: date,
    })

    return {
      status: true,
      availableTime: res.data.times,
    }
    // eslint-disable-next-line no-unreachable
  } catch (err: any) {
    return {
      status: false,
      availableTime: [],
      message:
        err?.response?.data?.message_error ||
        'Não foi possível consultar horário.',
    }
  }
}
