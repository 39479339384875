import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
`

export const TopContent = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 25px 70px;
  display: flex;
  flex-wrap: wrap;
  .right-content {
    max-width: 320px;
  }

  @media screen and (max-width: 961px) {
    padding: 25px;
  }
`
export const TopConsult = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
`
export const Content = styled.div`
  max-width: 100%;
  max-width: 700px;
  padding: 25px 70px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 750px) {
    padding: 15px;
  }
`
export const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
`
export const Form = styled.form`
  max-width: 600px;
`
