export const maskHeight = (num: string) => {
  if (num.length === 2) {
    num = num.replace(/(\d{1})(\d{1})/, '$1,$2')
  } else {
    num = num.replace(/(\d{1})(\d{2})/, '$1,$2')
  }
  return num
}

export const maskWeight = (num: string) => {
  if (num.length === 4) {
    num = num.replace(/(\d{3})(\d{1})/, '$1,$2')
  } else {
    num = num.replace(/(\d{3})(\d{1})/, '$1,$2')
  }
  return num
}
export function numUs(num: string) {
  return num.replace(/\D/g, '.')
}

export function dateMaskUS(date: string) {
  const newValue = date.split(/\D/g).reverse()

  return `${newValue[0]}-${newValue[1]}-${newValue[2]}`
}
