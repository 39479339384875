import React, { useContext, useState, createContext } from 'react'

// Types
import {
  schedulingProps,
  schedulingStateProps,
  SchenduleConfigProviderProps,
} from './scheduling.types'

export const defaultScheduling: schedulingProps = {
  userUuid: '',
  userDocument: '',
  dateSchedule: '',
  areaId: '',
  areaName: '',
  time: '',
  timeId: '',
  doctor: '',
  uuidDoctor: '',
  uuidPost: '',
  returnId: '',
}

const SchedulingContext = createContext<schedulingStateProps>({
  scheduling: {} as schedulingProps,
  setScheduling: () => {},
  verifyScheduleStorage: () => {},
})

const SchedulingProvider: React.FunctionComponent<SchenduleConfigProviderProps> =
  ({ children }) => {
    const [scheduling, setScheduling] =
      useState<schedulingProps>(defaultScheduling)

    const verifyScheduleStorage = (): void => {
      const itemStorage = window.localStorage.getItem('ScheduleStorage')

      if (itemStorage) {
        const datas = JSON.parse(itemStorage)
        setScheduling({
          userUuid: datas.userUuid,
          userDocument: datas.userDocument,
          dateSchedule: datas.dateSchedule,
          areaId: datas.areaId,
          areaName: datas.areaName,
          time: datas.time,
          timeId: datas.timeId,
          doctor: datas.doctor,
          uuidDoctor: datas.uuidDoctor,
          uuidPost: datas.uuidPost,
          returnId: datas.returnId,
        })
      }
    }

    const value: schedulingStateProps = {
      scheduling,
      setScheduling,
      verifyScheduleStorage,
    }

    return (
      <SchedulingContext.Provider value={value}>
        {children}
      </SchedulingContext.Provider>
    )
  }

const useScheduling = () => {
  return useContext(SchedulingContext)
}

export { SchedulingProvider, useScheduling }
