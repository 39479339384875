import React, { useMemo, useState } from 'react'
import {
  cpfCnpjMask,
  cpfCnpjUnmask,
  cpfValidator,
} from 'js-essentials-functions'
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  TextField as Input,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useSignIn } from '../../../context/authentication/signin.context'
import { schemaLogin } from '../validations'
import { authPatient } from '../services'

// Components
import { Button, Loading } from '../../../components'
import { alertDanger, alertSuccess } from '../../../utils/toast'
import Text from '../../../components/Text'

// Images
import logohabitual from '../../../assets/images/logohabitual.svg'
import bgdoctor from '../../../assets/images/bgdoctor.png'

import * as S from './styles'

const LoginHabitual = () => {
  const [loading, setLoading] = useState(false)
  const [admin, setAdmin] = useState(true)
  const [cpf, setCpf] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const { signInSuccessPatient } = useSignIn()
  const history = useHistory()

  const initialValues = useMemo(() => {
    return {
      login: '',
      password: '',
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: async (values: any) => {
      const datas = { email: values.login, password: values.password }
      console.log(datas)

      return alertDanger('Acesso indisponivel no momento!')
      // if (cnpjValidator(values.login)) {
      //   setLoading(true)
      //   const responseBtoB = await authBtoB(datas)

      //   if (responseBtoB.status) {
      //     setLoading(false)
      //     signInSuccess(responseBtoB.token)
      //     history.push('/dashboard')
      //     alertSuccess('Bem Vindo!')
      //   } else {
      //     setLoading(false)
      //     alertDanger(
      //       responseBtoB.message || 'Ocorreu um erro, tente novamente!'
      //     )
      //   }
      // } else if (validateEmail(values.login)) {
      //   setLoading(true)
      //   const response = await authPost(datas)

      //   if (response.status) {
      //     setLoading(false)
      //     signInSuccess(response.token)
      //     alertSuccess('Bem Vindo!')
      //     history.push('/dashboard')
      //   } else {
      //     setLoading(false)
      //     alertDanger(response.message || 'Ocorreu um erro, tente novamente!')
      //   }
      // } else {
      //   alertDanger('Dados inválidos!')
      // }
    },
  })

  const loginPatient = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const response = await authPatient({ cpf: cpfCnpjUnmask(cpf) })

    if (response.status) {
      signInSuccessPatient(response.token)
      setLoading(false)
      alertSuccess(response.message)
      history.push('/atendimentos')
      return null
    }
    setLoading(false)

    alertDanger(
      response.message || 'Ocorreu um erro inesperado, tente novamente!'
    )
    return null
  }

  if (loading)
    return <Loading title="Aguarde, você será redirecionado em breve!" />
  return (
    <S.Container>
      <S.Header>
        <div>
          <h4>(48) 3522-0397</h4>
          <h4>(48) 9 9834-2375</h4>
          <h4>suporte@habitualsaude.com.br</h4>
        </div>
      </S.Header>
      <S.SectionLogo>
        <S.Logo>
          <img
            src={logohabitual}
            alt="Logo em imagem que representa a marca da Starbem"
          />
        </S.Logo>
      </S.SectionLogo>
      <S.ContainerCard>
        <img src={bgdoctor} alt="Imagem de fundo do Medico" />
        {admin ? (
          <S.Card>
            <Text
              style={{ marginBottom: '15px', textAlign: 'center' }}
              weight="regular"
              size={24}
            >
              Acesso a Consulta
            </Text>
            <form onSubmit={formik.handleSubmit}>
              <Input
                label="Email ou CNPJ"
                placeholder="Informe seus dados"
                type="text"
                name="login"
                variant="outlined"
                className="input-default"
                onChange={formik.handleChange}
                value={formik.values.login}
                error={!!formik.errors.login}
                helperText={formik.errors.login}
              />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: 10 }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Senha
                </InputLabel>
                <OutlinedInput
                  label="Senha"
                  name="password"
                  autoComplete="off"
                  id="outlined-basic"
                  color="primary"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onMouseDown={() => {
                          setShowPassword(!showPassword)
                        }}
                        edge="end"
                        style={{ outline: 'none' }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                styles={{ width: '100%', marginTop: 20 }}
                color="blue-light"
                type="submit"
                disabled={!formik.isValid}
              >
                Entrar
              </Button>
            </form>
          </S.Card>
        ) : (
          <S.Card>
            <Text
              style={{ marginBottom: '15px', textAlign: 'center' }}
              weight="regular"
              size={24}
            >
              Acesso Paciente
            </Text>
            <form onSubmit={loginPatient}>
              <Input
                id="outlined-basic"
                label="CPF"
                placeholder="Informe seu cpf"
                type="text"
                name="cpf"
                variant="outlined"
                className="input-default"
                color="primary"
                value={cpfCnpjMask(cpf)}
                inputProps={{ maxLength: 14 }}
                onChange={(e) => setCpf(e.target.value)}
              />
              <Button
                styles={{ width: '100%', marginTop: 30 }}
                color="blue-light"
                // onClick={loginPatient}
                disabled={!cpfValidator(cpf)}
                type="submit"
              >
                Entrar
              </Button>
            </form>
          </S.Card>
        )}
        <S.AreaButton>
          <Button
            color="blue-light"
            type="button"
            size="small"
            onClick={() => setAdmin(!admin)}
          >
            {admin
              ? 'Acesse a área do Paciente'
              : 'Acesse a área administrativa'}
          </Button>
        </S.AreaButton>
      </S.ContainerCard>
    </S.Container>
  )
}

export default LoginHabitual
