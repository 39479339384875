const light = {
  colors: {
    white: '#ffffff',
    background: '#f0f2f8',
    primary: '#181d5f',
    secondary: '#ff3f72',
    secondaryLight: '#ffe4eb',
    dark: '#2c2b43',
    black: '#000000',
    gray10: '#f0f2f8',
    gray20: '#d9dce6',
    gray30: '#c5c9d5',
    gray50: '#787887',
    gray80: '#525166',
    blueLight: '#e3eefd',
    orangeLight: '#fbece6',
    gradientPrimary: '#ffe2b6',
    gradientSecondary: '#ffbcce',

    success: '#1fba5d',
    error: '#ff4242',
    warning: '#ff9b00',
  },
  radius: {
    default: 4,
    medium: 8,
    large: 12,
  },
}

export default light
