import React from 'react'
import moment from 'moment'

import * as S from './styles'

const Footer = () => {
  return (
    <S.Container>
      <div className="legal">
        {`© Copyright 2020-${moment().format(
          'YYYY'
        )} Starbem - Todos direitos reservados `}
        <strong>| Versão: 1.2.0</strong>
      </div>
    </S.Container>
  )
}

export default Footer
