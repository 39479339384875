import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;

  .patients-top {
    margin-left: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 961px) {
      margin-left: 20px;
    }
  }
  .search-patients {
    margin-left: 30px;
  }
`
export const TablePatients = styled.div`
  .primary-col {
    color: ${({ theme }) => theme.colors.black} !important;
  }
`
