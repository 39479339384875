import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Text from '../Text'
import './styles.scss'

const Menu = () => {
  const { pathname } = useLocation()
  const [patientOn, setPatientOn] = useState(true)
  const [postName, setPostName] = useState('')
  const history = useHistory()
  useEffect(() => {
    const datas = window.localStorage.getItem('@StarBem-web:user')
    if (datas) {
      const { uuid, name } = JSON.parse(datas)
      setPostName(name)
      if (!uuid) {
        setPatientOn(false)
      }
    }
  }, [])
  return (
    <nav id="menu">
      <ul>
        <li>
          <Link
            className={pathname === '/dashboard' ? 'active' : ''}
            to="/dashboard"
          >
            Agenda
          </Link>
        </li>
        {patientOn && (
          <>
            <li>
              <Link
                className={pathname === '/pacientes' ? 'active' : ''}
                to="/pacientes"
                onClick={() => {
                  if (pathname === '/pacientes') history.go(0)
                }}
              >
                Fila
              </Link>
            </li>
            <li>
              <Link
                className={pathname === '/historico' ? 'active' : ''}
                to="/historico"
                onClick={() => {
                  if (pathname === '/historico') history.go(0)
                }}
              >
                Histórico
              </Link>
            </li>
          </>
        )}
      </ul>
      <Text size={18} weight="semi-bold">
        {postName}
      </Text>
    </nav>
  )
}

export default Menu
