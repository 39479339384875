import React, { useContext, useState, createContext } from 'react'

// Types
import {
  AuthProps,
  AuthStateProps,
  SignInProviderProps,
} from './authentication.types'

const defaultSignIn: AuthProps = {
  userIsAuthenticated: false,
  token: '',
}

const SignInContext = createContext<AuthStateProps>({
  auth: defaultSignIn,
  signInSuccess: () => {},
  signInSuccessPatient: () => {},
  verifySignIn: () => false,
  verifySignInPatient: () => false,
  logout: () => {},
})

const SignInProvider: React.FunctionComponent<SignInProviderProps> = ({
  children,
}) => {
  const [auth, setAuth] = useState<AuthProps>(defaultSignIn)

  const logout = () => {
    window.localStorage.clear()
    setAuth(defaultSignIn)
  }

  const signInSuccess = (token: string): void => {
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('isAuthenticated', 'true')

    setAuth({
      userIsAuthenticated: true,
      token,
    })
  }
  const verifySignIn = (): boolean => {
    const isAuth = window.localStorage.getItem('token')
    if (isAuth) {
      return true
    }
    return false
  }

  const signInSuccessPatient = (token: string): void => {
    window.localStorage.setItem('tokenPatient', token)
    window.localStorage.setItem('isAuthenticatedPatient', 'true')

    setAuth({
      userIsAuthenticated: true,
      token,
    })
  }
  const verifySignInPatient = (): boolean => {
    const isAuth = window.localStorage.getItem('tokenPatient')
    if (isAuth) {
      return true
    }
    logout()
    return false
  }

  const value: AuthStateProps = {
    auth,
    signInSuccess,
    signInSuccessPatient,
    verifySignIn,
    verifySignInPatient,
    logout,
  }

  return (
    <SignInContext.Provider value={value}>{children}</SignInContext.Provider>
  )
}

const useSignIn = () => {
  return useContext(SignInContext)
}

export { SignInProvider, useSignIn }
