import * as yup from 'yup'

export const schemaLogin = yup.object().shape({
  login: yup.string().required('Informe o seus dados!'),
  password: yup
    .string()
    .required('Informe sua senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/.test(value)
      }
    ),
})

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
