import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(197, 201, 213, 0.6);
`
export const ModalContent = styled.div`
  width: 300px;
  padding: 25px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin: 50px auto;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
`

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
`
export const ContainerFilter = styled.div`
  padding: 0px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 561px) {
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 561px) {
      flex-direction: column;
    }
  }
  button {
    margin-left: 20px;

    @media screen and (max-width: 756px) {
      margin-top: 10px;
    }
  }
`

export const TablePatients = styled.div`
  overflow-x: auto;
  .primary-col {
    color: ${({ theme }) => theme.colors.black} !important;
  }
`
export const Pagination = styled.div`
  width: 100%;
  padding: 15px 40px;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`
