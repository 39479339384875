import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, var(--color-gradient-default) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ContainerCard = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 540px) {
    justify-content: space-between;
    padding: 30px 0;
  }
`
export const Logo = styled.div`
  img {
    width: 100%;
    max-width: 322px;
    margin-bottom: 20px;
    @media screen and (max-width: 540px) {
      max-width: 200px;
    }
  }
`

export const Card = styled.div`
  margin: 0px auto;
  width: 95%;
  max-width: 450px;
  max-height: 350px;
  background-color: white;
  border-radius: var(--border-radius-default);
  padding: 30px 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media screen and (min-width: 961px) {
    padding: 40px 60px;
  }
  @media screen and (max-width: 540px) {
    top: -30px;
    right: 0px;
    margin: 0px auto;
  }
`

export const AreaButton = styled.div`
  margin-top: 30px;
  z-index: 1000;
  position: fixed;
  bottom: 50px;
  left: 50px;

  @media screen and (max-width: 540px) {
    position: relative;
    margin: 30px auto;
    bottom: 0px;
    left: 0px;
  }
`
