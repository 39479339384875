import React from 'react'
import './styles.scss'

import { useHistory } from 'react-router-dom'
import { Button } from '../index'
import Text from '../Text'

interface ModalShowProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalShow: React.FunctionComponent<ModalShowProps> = ({
  setModalOpen,
}) => {
  const history = useHistory()
  function handleSubmit() {
    setModalOpen(false)
    history.push('/dashboard')
  }

  return (
    <>
      (
      <div className="modal-content">
        <div className="modal-container">
          <Text style={{ marginBottom: '25px' }} weight="semi-bold" size={24}>
            Agendamento realizado
          </Text>
          <Text
            style={{ marginBottom: '25px', textAlign: 'center' }}
            weight="regular"
            size={14}
          >
            As informações serão enviadas para o paciente por sms e/ou email
            cadastrados
          </Text>
          <Button
            type="button"
            color="primary"
            // @ts-ignore
            onClick={() => handleSubmit()}
            styles={{ marginTop: 10, width: 200, marginRight: 5 }}
          >
            Finalizar
          </Button>
        </div>
      </div>
    </>
  )
}

export default ModalShow
